<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="size"
      :height="size"
      viewBox="0 0 30 30"
  >
    <path
        class="stroke-current"
        fill="none"
        fill-rule="nonzero"
        stroke-width="1.5"
        d="M17 22v-4.978h-4V22H8v-6.626L14.944 7 22 15.374 21.888 22H17z" />
  </svg>
</template>


<script>
export default {
  props: {
    size: {
      type: Number,
      default: 30,
    },
  },
};
</script>


<style lang="scss" scoped>

</style>
