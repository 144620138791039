import Vue from 'vue';
import lineClamp from 'vue-line-clamp';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';
import smoothscroll from 'smoothscroll-polyfill';
import App from './App';
import router from './router';
import { i18n } from './i18n';
import store from './store';
import FirestoreRealtimePlugin from '@/plugins/FirestoreRealtimePlugin';
import './styles/index.scss';

Vue.use(FirestoreRealtimePlugin);
Vue.use(lineClamp);

Raven
  .config('https://1d67faf0f3d64dd3a480e675c9032bcb@sentry.io/1352672')
  .addPlugin(RavenVue, Vue)
  .install();

// kick off the polyfill for scrollIntoView
smoothscroll.polyfill();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
