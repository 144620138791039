<template>
  <div class="relative">
    <IconInfo
        ref="icon-info"
        :is-active="tooltipIsVisible"
        tabindex="0"
        class="cursor-pointer"
        @mouseenter.native="tipRef.show()"
        @mouseleave.native="tipRef.hide()"
        @click.native.stop.prevent="toggleTooltip"
        @touchstart.native.stop.prevent="toggleTooltip"
        @focus.native="tipRef.show()"
        @blur.native="tipRef.hide()"
        @keydown.native.space.stop.prevent="toggleTooltip"
    />
    <div
        ref="tooltip-content"
        class="tooltip-content relative"
    >
      {{ this.tooltipContent }}
      <IconCross
          class="absolute close-icon"
          @click.native="tipRef.hide()"
      />
    </div>
  </div>
</template>


<script>
import IconInfo from '@/components/icon/IconInfo';
import IconCross from '@/components/icon/IconCross';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';


export default {
  props: {
    tooltipContent: {
      type: String,
      default: '-',
    },
  },
  components: {
    IconInfo,
    IconCross,
  },
  data: () => ({
    tooltipIsVisible: false,
    tipRef: null,
  }),
  mounted() {
    // Default options
    tippy.setDefaultProps({
      arrow: false,
      duration: 250,
      animation: 'scale',
      theme: 'swisskrono',
    });
    this.tipRef = tippy(this.$refs['icon-info'].$el, {
      interactive: true,
      trigger: 'manual',
      maxWidth: 350,
      content: this.$refs['tooltip-content'],
      onShow: () => {
        this.tooltipIsVisible = true;
      },
      onHide: () => {
        this.tooltipIsVisible = false;
      },
    });
  },
  computed: {
    style() {
      return {
        bottom: '30px',
      };
    },
  },
  methods: {
    toggleTooltip() {
      if (this.tooltipIsVisible) this.tipRef.hide();
      else this.tipRef.show();
    },
  },
  beforeDestroy() {
    this.tipRef.destroy();
  },
};
</script>


<style lang="scss">


.close-icon {
  top: 12px;
  right: 12px;
}
/* Custom tippy theme for the tooltip */
.tippy-box[data-theme="swisskrono"] {
  width: 350px;
  @apply shadow rounded-none;
  .tippy-content {
    padding: 0;
  }

  background-color: config('colors.pure-white');
  .tooltip-content {
    @apply p-30;
  }
  .tippy-content {
    @apply font-sans-regular text-base text-charcoal-grey leading-29; // TODO: Apply text-style-7
    @apply text-left;
  }
}
/* Custom tippy theme for the tooltip */
// .tippy-tooltip.swisskrono-theme {
//   @apply shadow rounded-none;

//   padding: 0;

//   .tippy-box {
//     background-color: config('colors.pure-white');
//   }

//   .tippy-content {
//     @apply font-sans-regular text-base text-charcoal-grey leading-29; // TODO: Apply text-style-7
//     @apply text-left;
//   }
// }
</style>
