/* eslint-disable import/prefer-default-export */

export function rightBorderIsVisibleForItemIndex(itemIndex, activeItemIndex, itemCount) {
  if (itemCount === 1) return false;
  if (itemCount === 2 && activeItemIndex >= itemCount) return true;
  if (itemIndex === activeItemIndex) return false;
  if (itemIndex === itemCount - 1) return false;

  return itemIndex + 1 !== activeItemIndex;
}
