export default {
  rw: {
    c100rw: {
      label: 'C',
    },
    c50rw: {
      label: 'C',
      subLabel: '50-3150',
    },
    ctr100rw: {
      label: 'C',
      subLabel: 'tr',
    },
  },
  lnw: {
    ci100lnw: {
      label: 'CI',
    },
    ci50lnw: {
      label: 'CI',
      subLabel: '50-2500',
    },
  },
};
