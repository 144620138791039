<template>
  <div
      v-if="isShowing && !isCookieAccepted"
      class="fixed pin-t w-full z-50 opacity-90 bg-white"
  >
    <div>
      <div class="flex flex-col justify-between">
        <div class="pl-100 pr-100 mt-30 mb-20 text-base leading-22">
          {{ $t('cookies.cookies_text') }}
            <p
                class="inline-block text-curious-blue no-underline cursor-pointer"
                @click="$router.push({ name: 'DataPrivacyView', hash: '#cookie-policy', params: { noScrollReset: true } })"
            >
              {{ $t('cookies.cookies_data_protection_text') }}
            </p>
        </div>
        <div>
          <UiButton
              class="w-50 h-50 ml-100 mb-30 border-1 border-cool-grey"
              action="accept-cookies"
              @accept-cookies="acceptCookies"
          >
            OK
          </UiButton>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import UiButton from '../ui/UiButton';

export default {
  components: {
    UiButton,
  },
  data() {
    return {
      isShowing: true,
    }
  },
  computed: {
    isCookieAccepted() {
      if (document.cookie.match('cookiesAccepted=true')) {
        return true;
      }
      return false;
    },
  },
  methods: {
    acceptCookies() {
      this.isShowing = false;
      const today = new Date();
      // Add six months valid cookie
      const sixMonths = new Date(today.getTime() + 180000 * 60 * 60 * 24);
      document.cookie = `cookiesAccepted=${true}; expires=${sixMonths}; path=/`
    },
  }
}
</script>


<style scoped>

</style>
