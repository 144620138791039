<template>
  <div>
    <Transition name="zoom-in-bottom">
      <div
          v-if="(nextButtonIsVisible || nextButtonShowsFilterResults)
          && $route.name === 'AssemblySearchView'"
          role="button"
          class="next-button fixed w-184 flex flex-row justify-between
          bg-emerald text-right mr-40 p-20 cursor-pointer"
          :class="[
              nextButtonShowsFilterResults ? 'items-baseline' : 'items-center',
              !nextButtonShowsFilterResults
              || filteredProducts.length ? 'cursor-pointer' : 'cursor-not-allowed',
          ]"
          @click="onButtonPress"
      >
        <div
          v-if="allProductsIsLoading && nextButtonShowsFilterResults"
          class="m-auto"
        >
          <IconLoader />
        </div>
        <div
            v-else-if="nextButtonShowsFilterResults"
            class="flex flex-col items-start text-pure-white"
        >
          <p class="text-xl font-sans-bold">
            {{ filteredProducts.length }}
          </p>
          <p class="text-base">
            <template v-if="filteredProducts.length">
              {{ $t('navigation.bottom.next_button.result_count_text') }}
            </template>
            <template v-else>
              {{ $t('navigation.bottom.next_button.result_count_empty_text') }}
            </template>
          </p>
        </div>
        <div
            v-else
            class="text-pure-white"
        >
          <div class="font-sans-bold text-sm uppercase">
            {{ $t('navigation.bottom.next_button.main_text') }}
          </div>
        </div>
        <div
          v-show="filteredProducts.length || !nextButtonShowsFilterResults"
          class="my-auto"
        >
          <IconArrow
            class="text-pure-white"
          />
        </div>

      </div>
    </Transition>
    <nav class="nav-bottom bg-charcoal-grey shadow-light">
      <ul class="list-container list-reset h-full flex items-center space-between">
        <li
            v-for="(navigationItem, index) in navigationItems"
            :key="index"
            class="relative h-full border-r border-dark-grey"
        >
          <p
              v-if="navigationItem.onPress"
              :title="$t(navigationItem.navText)"
              class="navigation-item pl-25 pr-30 cursor-pointer tablet-landscape:pl-32
              tablet-landscape:pr-40"
              :class="[{ 'bg-dark-grey': index === itemHoverIndex }, navigationItem.classNames]"
              @mouseenter="itemHoverIndex = index"
              @mouseleave="itemHoverIndex = -1"
              @click="navigationItem.onPress()"
          >
            <img
                :src="baseUrl + navigationItem.imageSrc"
                :alt="$t(navigationItem.navText)"
                class="icon h-40 pr-10 tablet-landscape:pr-14 max-h-40"
            >
            <span class="navigation-text">
              {{ $t(navigationItem.navText) }}
            </span>
          </p>
          <RouterLink
              v-else
              :to="navigationItem.routeTo"
              :title="$t(navigationItem.navText)"
              class="navigation-item pl-25 pr-30 tablet-landscape:pl-32 tablet-landscape:pr-40"
              :class="{ 'bg-dark-grey': index === itemHoverIndex }"
              @mouseenter.native="itemHoverIndex = index"
              @mouseleave.native="itemHoverIndex = -1"
          >
            <img
                :src="baseUrl + navigationItem.imageSrc"
                :alt="$t(navigationItem.navText)"
                class="icon h-40 pr-10 tablet-landscape:pr-14 max-h-40"
            >
            <span class="navigation-text">
              {{ $t(navigationItem.navText) }}
            </span>
          </RouterLink>
        </li>
        <li class="flex-grow flex items-center justify-end pr-26">
          <img
              :src="`${baseUrl}images/brand-icon.svg`"
              alt="SwissKrono"
              title="SwissKrono"
              class="tablet-portrait:block tablet-landscape:hidden"
          >
          <img
              :src="`${baseUrl}images/brand-logo.svg`"
              alt="SwissKrono"
              title="SwissKrono"
              class="hidden tablet-landscape:block"
          >
        </li>
      </ul>
    </nav>
  </div>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import IconArrow from '@/components/icon/IconArrow';
import IconLoader from '@/components/icon/IconLoader';


export default {
  components: {
    IconArrow,
    IconLoader,
  },
  data: () => ({
    baseUrl: process.env.BASE_URL,
    itemHoverIndex: -1,
  }),
  computed: {
    ...mapState('assemblySearch', {
      activeStepIndex: 'activeStepIndex',
      filteredProducts: 'filteredProducts',
      allProductsIsLoading: 'allProductsIsLoading',
    }),
    ...mapGetters('assemblySearch', {
      searchTypeIsManual: 'searchTypeIsManual',
      searchTypeIsPreset: 'searchTypeIsPreset',
      allowedStepIndexes: 'allowedStepIndexes',
    }),
    ...mapGetters('user', {
      isAuthenticated: 'isAuthenticated',
    }),
    routeParamLocale() {
      return { locale: this.$i18n.locale };
    },
    navigationItems() {
      return [{
        imageSrc: 'images/icons/navigation-bar/home.svg',
        navText: 'navigation.bottom.home_text',
        routeTo: { name: 'HomeView', params: { ...this.routeParamLocale } },
      }, {
        imageSrc: 'images/icons/navigation-bar/assembly.svg',
        navText: 'navigation.bottom.assembly_text',
        routeTo: { name: 'AssemblySearchView', params: { ...this.routeParamLocale, searchType: 'manual' } },
      }, {
        imageSrc: 'images/icons/navigation-bar/tools.svg',
        navText: 'navigation.bottom.tools_text',
        routeTo: { name: 'ToolsView', params: { ...this.routeParamLocale } },
      }, {
        imageSrc: 'images/icons/navigation-bar/bookmarks.svg',
        navText: 'navigation.bottom.bookmark_text',
        classNames: this.$route.name === 'BookmarksView' ? 'router-link-exact-active' : '',
        onPress: this.onBookmarksPress,
      }];
    },
    nextButtonIsVisible() {
      return this.allowedStepIndexes.includes(this.activeStepIndex + 1);
    },
    nextButtonShowsFilterResults() {
      return ((this.searchTypeIsManual && this.activeStepIndex === 1)
        || (this.searchTypeIsPreset && this.activeStepIndex === 2));
    },
  },
  methods: {
    ...mapActions('ui', {
      updateModalState: 'updateModalState',
    }),
    ...mapActions('assemblySearch', {
      nextActiveStepIndex: 'nextActiveStepIndex',
    }),
    onButtonPress() {
      if (this.allProductsIsLoading && this.nextButtonShowsFilterResults) return;
      if (this.allowedStepIndexes.includes(this.activeStepIndex + 1)) {
        this.nextActiveStepIndex();
      } else {
        if (this.filteredProducts.length === 0) return;

        this.nextActiveStepIndex();
        this.$router.push({ name: 'ProductSearchResultsView' });
      }
    },
    onBookmarksPress() {
      if (this.isAuthenticated) {
        this.$router.push({ name: 'BookmarksView', params: { ...this.routeParamLocale } });
        return;
      }

      this.updateModalState({ isVisible: true, type: 'log-in' });
    },
  },
};
</script>


<style lang="scss" scoped>
@import '../../styles/variables';

.next-button {
  bottom: $sticky-nav-bottom-h;
  right: 40px;
}

.nav-bottom {
  height: $sticky-nav-bottom-h;

  .navigation-item {
    @apply h-full block flex items-center no-underline;

    transition: background-color .3s; // TODO: Extract to transitions.scss

    .icon {
      flex-basis: 40px;
    }

    .navigation-text {
      @apply .font-sans-regular .tracking-tight .text-base .text-pure-white .pt-3;
    }

    &.router-link-exact-active {
      @apply bg-dark-grey;
    }
  }
}
</style>
