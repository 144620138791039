<template>
  <form novalidate>
    <UiTextInput
        v-if="modalTypeIsSignUp && !isResetPasswordSetPasswordType"
        v-model="formData.companyName"
        name="companyName"
        autocomplete="true"
        :placeholder="$t('modal.authentication.form.company_name.placeholder_text')"
        :no-icons="true"
        class="mb-7"
    />
    <UiTextInput
        v-if="modalTypeIsSignUp && !isResetPasswordSetPasswordType"
        v-model="formData.firstName"
        name="firstName"
        autocomplete="true"
        :placeholder="$t('modal.authentication.form.firstName.placeholder_text')"
        :no-icons="true"
        :rules="formRules.firstName"
        class="mb-7"
    />
    <UiTextInput
        v-if="modalTypeIsSignUp && !isResetPasswordSetPasswordType"
        v-model="formData.lastName"
        name="lastName"
        autocomplete="true"
        :placeholder="$t('modal.authentication.form.lastName.placeholder_text')"
        :no-icons="true"
        :rules="formRules.lastName"
        class="mb-7"
    />
    <UiTextInput
        v-if="modalTypeIsSignUp && !isResetPasswordSetPasswordType"
        v-model="formData.street "
        name="street "
        autocomplete="true"
        :placeholder="$t('modal.authentication.form.street.placeholder_text')"
        :no-icons="true"
        :rules="formRules.street "
        class="mb-7"
    />
    <div class="flex justify-between">
      <UiTextInput
          v-if="modalTypeIsSignUp && !isResetPasswordSetPasswordType"
          v-model="formData.zip"
          name="zip"
          autocomplete="true"
          :placeholder="$t('modal.authentication.form.zip.placeholder_text')"
          :no-icons="true"
          :rules="formRules.zip"
          class="mb-7 w-1/3"
      />
      <UiTextInput
          v-if="modalTypeIsSignUp && !isResetPasswordSetPasswordType"
          v-model="formData.city"
          name="city"
          autocomplete="true"
          :placeholder="$t('modal.authentication.form.city.placeholder_text')"
          :no-icons="true"
          :rules="formRules.city"
          class="mb-7 w-2/3 ml-10"
      />
    </div>
    <UiDropDown
      v-if="modalTypeIsSignUp && !isResetPasswordSetPasswordType"
      v-model="formData.country"
      class="mb-7"
      name="country"
      :options="$i18n.locale === 'en' ? eNcountries : dEcountries"
      :placeholder="$t('modal.authentication.form.country.placeholder_text')"
      :no-icons="true"
      :rules="formRules.country"
    />
    <UiTextInput
        v-if="modalTypeIsSignUp && !isResetPasswordSetPasswordType"
        v-model="formData.phoneNumber"
        name="phoneNumber"
        autocomplete="true"
        :placeholder="$t('modal.authentication.form.phone_number.placeholder_text')"
        :no-icons="true"
        class="mb-7"
    />
    <UiTextInput
        v-if="!isResetPasswordSetPasswordType"
        v-model="formData.email"
        type="email"
        name="email"
        autocomplete="true"
        :placeholder="$t('modal.authentication.form.email.placeholder_text')"
        :no-success-icon="true"
        :rules="formRules.email"
        class="mb-7"
    />
    <template v-if="!modalTypeIsResetPasswordSendEmail">
      <UiTextInput
          v-model="formData.password"
          type="password"
          name="password"
          :placeholder="$t('modal.authentication.form.password.placeholder_text')"
          :rules="(modalTypeIsSignUp || isResetPasswordSetPasswordType) ? formRules.password : []"
          :no-icons="!modalTypeIsSignUp && !isResetPasswordSetPasswordType"
          class="mb-10"
      />
      <p
          v-if="modalTypeIsSignUp || isResetPasswordSetPasswordType"
          class="mt-4 mb-20 text-sm text-cool-grey"
      >
        {{ $t('modal.authentication.form.password.description_text') }}
      </p>
    </template>
    <UiCheckbox
        v-if="modalTypeIsSignUp && !isResetPasswordSetPasswordType"
        v-model="dataProtectionIsAgreed"
        val="dataProtectionIsAgreed"
        no-label-color
        :no-success-icon="true"
        :rules="formRules.dataProtect"
    >
      <i18n
          path="modal.authentication.register.data_protection.main_text"
          tag="p"
      >
        <a
            place="dataProtectionLink"
            :href="$t('modal.authentication.register.data_protection.label_link')"
            target="_blank"
            class="font-sans-bold no-underline text-charcoal-grey"
        >
          {{ $t('modal.authentication.register.data_protection.label_text') }}
        </a>
      </i18n>
    </UiCheckbox>
    <UiCheckbox
        v-if="modalTypeIsSignUp && !isResetPasswordSetPasswordType"
        v-model="formData.newsletterOptIn"
        val="advEmailsIsAgreed"
        no-label-color
    >
      <i18n
          path="modal.authentication.getUpdates.data_protection.main_text"
          tag="p"
      >
        {{ $t('modal.authentication.getUpdates.data_protection.label_text') }}
      </i18n>
    </UiCheckbox>
    <UiButton
        class="w-full font-sans-bold mt-14 py-15 text-pure-white bg-charcoal-grey uppercase"
        type="submit"
        @click.native.prevent="onFormSubmission"
        @keydown.space.native.prevent="onFormSubmission"
    >
      {{ submitButtonLabel }}
    </UiButton>
    <TransitionCollapse>
      <p
          v-if="formValidationMessage"
          class="text-sm text-faded-red leading-43"
      >
        {{ formValidationMessage }}
      </p>
    </TransitionCollapse>
  </form>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import TransitionCollapse from '@/components/transition/TransitionCollapse';
import UiButton from '@/components/ui/UiButton';
import UiCheckbox from '@/components/ui/UiCheckbox';
import UiDropDown from '@/components/ui/UiDropDown';
import UiTextInput from '@/components/ui/UiTextInput';


export default {
  props: {
    isResetPasswordSetPasswordType: {
      type: Boolean,
      default: false,
    },
    submitButtonLabel: {
      type: String,
      default: '',
    },
  },
  components: {
    TransitionCollapse,
    UiButton,
    UiCheckbox,
    UiTextInput,
    UiDropDown,
  },
  data: () => ({
    eNcountries: [
      {name: "Afghanistan"},
      {name: "Albania"},
      {name: "Algeria"},
      {name: "American_Samoa"},
      {name: "Andorra"},
      {name: "Angola"},
      {name: "Anguilla"},
      {name: "Antarctica"},
      {name: "Antigua_and_Barbuda"},
      {name: "Argentina"},
      {name: "Armenia"},
      {name: "Aruba"},
      {name: "Australia"},
      {name: "Austria"},
      {name: "Azerbaijan"},
      {name: "Bahrain"},
      {name: "Bangladesh"},
      {name: "Barbados"},
      {name: "Belarus"},
      {name: "Belgium"},
      {name: "Belize"},
      {name: "Benin"},
      {name: "Bermuda"},
      {name: "Bhutan"},
      {name: "Bolivia"},
      {name: "Bonaire_Sint_Eustatius_and_Saba"},
      {name: "Bosnia_and_Herzegovina"},
      {name: "Botswana"},
      {name: "Bouvet_Island"},
      {name: "Brazil"},
      {name: "British_Indian_Ocean_Territory"},
      {name: "British_Virgin_Islands"},
      {name: "Brunei"},
      {name: "Bulgaria"},
      {name: "Burkina_Faso"},
      {name: "Burundi"},
      {name: "Cambodia"},
      {name: "Cameroon"},
      {name: "Canada"},
      {name: "Cape_Verde"},
      {name: "Cayman_Islands"},
      {name: "Central_African_Republic"},
      {name: "Chad"},
      {name: "Chile"},
      {name: "China"},
      {name: "Christmas_Island"},
      {name: "Cocos_Islands"},
      {name: "Colombia"},
      {name: "Comoros"},
      {name: "Congo"},
      {name: "Congo_Brazzaville"},
      {name: "Cook_Islands"},
      {name: "Costa_Rica"},
      {name: "Croatia"},
      {name: "Cuba"},
      {name: "Curaçao"},
      {name: "Cyprus"},
      {name: "Czech_Republic"},
      {name: "Cote_dIvoire"},
      {name: "Denmark"},
      {name: "Djibouti"},
      {name: "Dominica"},
      {name: "Dominican_Republic"},
      {name: "Ecuador"},
      {name: "Egypt"},
      {name: "El_Salvador"},
      {name: "Equatorial_Guinea"},
      {name: "Eritrea"},
      {name: "Estonia"},
      {name: "Ethiopia"},
      {name: "Falkland_Islands"},
      {name: "Faroes"},
      {name: "Fiji"},
      {name: "Finland"},
      {name: "France"},
      {name: "French_Guiana"},
      {name: "French_Polynesia"},
      {name: "French_Southern_Territories"},
      {name: "Gabon"},
      {name: "Gambia"},
      {name: "Georgia"},
      {name: "Germany"},
      {name: "Ghana"},
      {name: "Gibraltar"},
      {name: "Greece"},
      {name: "Greenland"},
      {name: "Grenada"},
      {name: "Guadeloupe"},
      {name: "Guam"},
      {name: "Guatemala"},
      {name: "Guernsey"},
      {name: "Guinea"},
      {name: "Guinea_Bissau"},
      {name: "Guyana"},
      {name: "Haiti"},
      {name: "Heard_Island_and_McDonald_Islands"},
      {name: "Honduras"},
      {name: "Hong_Kong_SAR_of_China"},
      {name: "Hungary"},
      {name: "Iceland"},
      {name: "India"},
      {name: "Indonesia"},
      {name: "Iran"},
      {name: "Iraq"},
      {name: "Ireland"},
      {name: "Isle_of_Man"},
      {name: "Israel"},
      {name: "Italy"},
      {name: "Jamaica"},
      {name: "Japan"},
      {name: "Jersey"},
      {name: "Jordan"},
      {name: "Kazakhstan"},
      {name: "Kenya"},
      {name: "Kiribati"},
      {name: "Kuwait"},
      {name: "Kyrgyzstan"},
      {name: "Laos"},
      {name: "Latvia"},
      {name: "Lebanon"},
      {name: "Lesotho"},
      {name: "Liberia"},
      {name: "Libya"},
      {name: "Liechtenstein"},
      {name: "Lithuania"},
      {name: "Luxembourg"},
      {name: "Macao_SAR_of_China"},
      {name: "Macedonia"},
      {name: "Madagascar"},
      {name: "Malawi"},
      {name: "Malaysia"},
      {name: "Maldives"},
      {name: "Mali"},
      {name: "Malta"},
      {name: "Marshall_Islands"},
      {name: "Martinique"},
      {name: "Mauritania"},
      {name: "Mauritius"},
      {name: "Mayotte"},
      {name: "Mexico"},
      {name: "Micronesia"},
      {name: "Moldova"},
      {name: "Monaco"},
      {name: "Mongolia"},
      {name: "Montenegro"},
      {name: "Montserrat"},
      {name: "Morocco"},
      {name: "Mozambique"},
      {name: "Myanmar"},
      {name: "Namibia"},
      {name: "Nauru"},
      {name: "Nepal"},
      {name: "Netherlands"},
      {name: "New_Caledonia"},
      {name: "New_Zealand"},
      {name: "Nicaragua"},
      {name: "Niger"},
      {name: "Nigeria"},
      {name: "Niue"},
      {name: "Norfolk_Island"},
      {name: "North_Korea"},
      {name: "Northern_Marianas"},
      {name: "Norway"},
      {name: "Oman"},
      {name: "Pakistan"},
      {name: "Palau"},
      {name: "Palestine"},
      {name: "Panama"},
      {name: "Papua_New_Guinea"},
      {name: "Paraguay"},
      {name: "Peru"},
      {name: "Philippines"},
      {name: "Pitcairn_Islands"},
      {name: "Poland"},
      {name: "Portugal"},
      {name: "Puerto_Rico"},
      {name: "Qatar"},
      {name: "Reunion"},
      {name: "Romania"},
      {name: "Russia"},
      {name: "Rwanda"},
      {name: "Saint_Barthélemy"},
      {name: "Saint_Helena_Ascension_and_Tristan_da_Cunha"},
      {name: "Saint_Kitts_and_Nevis"},
      {name: "Saint_Lucia"},
      {name: "Saint_Martin"},
      {name: "Saint_Pierre_and_Miquelon"},
      {name: "Saint_Vincent_and_the_Grenadines"},
      {name: "Samoa"},
      {name: "San_Marino"},
      {name: "Saudi_Arabia"},
      {name: "Senegal"},
      {name: "Serbia"},
      {name: "Seychelles"},
      {name: "Sierra_Leone"},
      {name: "Singapore"},
      {name: "Sint_Maarten"},
      {name: "Slovakia"},
      {name: "Slovenia"},
      {name: "Solomon_Islands"},
      {name: "Somalia"},
      {name: "South_Africa"},
      {name: "South_Georgia_and_the_South_Sandwich_Islands"},
      {name: "South_Korea"},
      {name: "South_Sudan"},
      {name: "Spain"},
      {name: "Sri_Lanka"},
      {name: "Sudan"},
      {name: "Suriname"},
      {name: "Svalbard"},
      {name: "Swaziland"},
      {name: "Sweden"},
      {name: "Switzerland"},
      {name: "Syria"},
      {name: "Sao_Tome_e_Principe"},
      {name: "Taiwan"},
      {name: "Tajikistan"},
      {name: "Tanzania"},
      {name: "Thailand"},
      {name: "The_Bahamas"},
      {name: "Timor_Leste"},
      {name: "Togo"},
      {name: "Tokelau"},
      {name: "Tonga"},
      {name: "Trinidad_and_Tobago"},
      {name: "Tunisia"},
      {name: "Turkey"},
      {name: "Turkmenistan"},
      {name: "Turks_and_Caicos_Islands"},
      {name: "Tuvalu"},
      {name: "US_Virgin_Islands"},
      {name: "Uganda"},
      {name: "Ukraine"},
      {name: "United_Arab_Emirates"},
      {name: "United_Kingdom"},
      {name: "United_States"},
      {name: "United_States_Minor_Outlying_Islands"},
      {name: "Uruguay"},
      {name: "Uzbekistan"},
      {name: "Vanuatu"},
      {name: "Vatican_City"},
      {name: "Venezuela"},
      {name: "Vietnam"},
      {name: "Wallis_and_Futuna"},
      {name: "Western_Sahara"},
      {name: "Yemen"},
      {name: "Zambia"},
      {name: "Zimbabwe"},
    ],
    dEcountries: [
      {name: "Afghanistan"},
      {name: "Albania"},
      {name: "Algeria"},
      {name: "American_Samoa"},
      {name: "Andorra"},
      {name: "Angola"},
      {name: "Anguilla"},
      {name: "Antarctica"},
      {name: "Antigua_and_Barbuda"},
      {name: "Argentina"},
      {name: "Armenia"},
      {name: "Aruba"},
      {name: "Azerbaijan"},
      {name: "Australia"},
      {name: "Egypt"},
      {name: "Equatorial_Guinea"},
      {name: "Ethiopia"},
      {name: "Bahrain"},
      {name: "Bangladesh"},
      {name: "Barbados"},
      {name: "Belgium"},
      {name: "Belize"},
      {name: "Benin"},
      {name: "Bermuda"},
      {name: "Bhutan"},
      {name: "Bolivia"},
      {name: "Bonaire_Sint_Eustatius_and_Saba"},
      {name: "Bosnia_and_Herzegovina"},
      {name: "Botswana"},
      {name: "Bouvet_Island"},
      {name: "Brazil"},
      {name: "British_Virgin_Islands"},
      {name: "British_Indian_Ocean_Territory"},
      {name: "Brunei"},
      {name: "Bulgaria"},
      {name: "Burkina_Faso"},
      {name: "Burundi"},
      {name: "Cayman_Islands"},
      {name: "Chile"},
      {name: "China"},
      {name: "Cook_Islands"},
      {name: "Costa_Rica"},
      {name: "Curaçao"},
      {name: "Germany"},
      {name: "The_Bahamas"},
      {name: "Dominica"},
      {name: "Dominican_Republic"},
      {name: "Djibouti"},
      {name: "Denmark"},
      {name: "Ecuador"},
      {name: "El_Salvador"},
      {name: "Cote_dIvoire"},
      {name: "Eritrea"},
      {name: "Estonia"},
      {name: "Falkland_Islands"},
      {name: "Fiji"},
      {name: "Finland"},
      {name: "France"},
      {name: "French_Polynesia"},
      {name: "French_Guiana"},
      {name: "Faroes"},
      {name: "Gabon"},
      {name: "Gambia"},
      {name: "Georgia"},
      {name: "Ghana"},
      {name: "Gibraltar"},
      {name: "Grenada"},
      {name: "Greece"},
      {name: "United_Kingdom"},
      {name: "Greenland"},
      {name: "Guadeloupe"},
      {name: "Guam"},
      {name: "Guatemala"},
      {name: "Guernsey"},
      {name: "Guinea"},
      {name: "Guinea_Bissau"},
      {name: "Guyana"},
      {name: "Haiti"},
      {name: "Heard_Island_and_McDonald_Islands"},
      {name: "Honduras"},
      {name: "Hong_Kong_SAR_of_China"},
      {name: "India"},
      {name: "Indonesia"},
      {name: "Isle_of_Man"},
      {name: "Iraq"},
      {name: "Iran"},
      {name: "Ireland"},
      {name: "Iceland"},
      {name: "Israel"},
      {name: "Italy"},
      {name: "Jamaica"},
      {name: "Japan"},
      {name: "Yemen"},
      {name: "Jersey"},
      {name: "Jordan"},
      {name: "Cambodia"},
      {name: "Cameroon"},
      {name: "Canada"},
      {name: "Cape_Verde"},
      {name: "Kazakhstan"},
      {name: "Qatar"},
      {name: "Kenya"},
      {name: "Kyrgyzstan"},
      {name: "Kiribati"},
      {name: "United_States_Minor_Outlying_Islands"},
      {name: "Cocos_Islands"},
      {name: "Colombia"},
      {name: "Comoros"},
      {name: "Congo"},
      {name: "Congo_Brazzaville"},
      {name: "Croatia"},
      {name: "Cuba"},
      {name: "Kuwait"},
      {name: "Laos"},
      {name: "Lesotho"},
      {name: "Latvia"},
      {name: "Lebanon"},
      {name: "Liberia"},
      {name: "Libya"},
      {name: "Liechtenstein"},
      {name: "Lithuania"},
      {name: "Luxembourg"},
      {name: "Macao_SAR_of_China"},
      {name: "Madagascar"},
      {name: "Malawi"},
      {name: "Malaysia"},
      {name: "Maldives"},
      {name: "Mali"},
      {name: "Malta"},
      {name: "Morocco"},
      {name: "Marshall_Islands"},
      {name: "Martinique"},
      {name: "Mauritania"},
      {name: "Mauritius"},
      {name: "Mayotte"},
      {name: "Macedonia"},
      {name: "Mexico"},
      {name: "Micronesia"},
      {name: "Moldova"},
      {name: "Monaco"},
      {name: "Mongolia"},
      {name: "Montenegro"},
      {name: "Montserrat"},
      {name: "Mozambique"},
      {name: "Myanmar"},
      {name: "Namibia"},
      {name: "Nauru"},
      {name: "Nepal"},
      {name: "New_Caledonia"},
      {name: "New_Zealand"},
      {name: "Nicaragua"},
      {name: "Netherlands"},
      {name: "Niger"},
      {name: "Nigeria"},
      {name: "Niue"},
      {name: "North_Korea"},
      {name: "Norfolk_Island"},
      {name: "Norway"},
      {name: "Northern_Marianas"},
      {name: "Oman"},
      {name: "Austria"},
      {name: "Pakistan"},
      {name: "Palau"},
      {name: "Palestine"},
      {name: "Panama"},
      {name: "Papua_New_Guinea"},
      {name: "Paraguay"},
      {name: "Peru"},
      {name: "Philippines"},
      {name: "Pitcairn_Islands"},
      {name: "Poland"},
      {name: "Portugal"},
      {name: "Puerto_Rico"},
      {name: "Reunion"},
      {name: "Rwanda"},
      {name: "Romania"},
      {name: "Russia"},
      {name: "Saint_Pierre_and_Miquelon"},
      {name: "Saint_Barthélemy"},
      {name: "Saint_Martin"},
      {name: "Solomon_Islands"},
      {name: "Saint_Lucia"},
      {name: "Zambia"},
      {name: "Samoa"},
      {name: "San_Marino"},
      {name: "Saudi_Arabia"},
      {name: "Sweden"},
      {name: "Switzerland"},
      {name: "Senegal"},
      {name: "Serbia"},
      {name: "Seychelles"},
      {name: "Sierra_Leone"},
      {name: "Zimbabwe"},
      {name: "Singapore"},
      {name: "Sint_Maarten"},
      {name: "Slovakia"},
      {name: "Slovenia"},
      {name: "Somalia"},
      {name: "South_Africa"},
      {name: "Spain"},
      {name: "Sri_Lanka"},
      {name: "Saint_Helena_Ascension_and_Tristan_da_Cunha"},
      {name: "Saint_Kitts_and_Nevis"},
      {name: "Saint_Vincent_and_the_Grenadines"},
      {name: "Sudan"},
      {name: "Suriname"},
      {name: "Svalbard"},
      {name: "Swaziland"},
      {name: "Syria"},
      {name: "Sao_Tome_e_Principe"},
      {name: "South_Georgia_and_the_South_Sandwich_Islands"},
      {name: "French_Southern_Territories"},
      {name: "South_Korea"},
      {name: "South_Sudan"},
      {name: "Tajikistan"},
      {name: "Taiwan"},
      {name: "Tanzania"},
      {name: "Thailand"},
      {name: "Togo"},
      {name: "Timor_Leste"},
      {name: "Tokelau"},
      {name: "Tonga"},
      {name: "Trinidad_and_Tobago"},
      {name: "Chad"},
      {name: "Czech_Republic"},
      {name: "Tunisia"},
      {name: "Turkmenistan"},
      {name: "Turks_and_Caicos_Islands"},
      {name: "Tuvalu"},
      {name: "Turkey"},
      {name: "US_Virgin_Islands"},
      {name: "Uganda"},
      {name: "Ukraine"},
      {name: "Hungary"},
      {name: "Uruguay"},
      {name: "Uzbekistan"},
      {name: "Vanuatu"},
      {name: "Vatican_City"},
      {name: "Venezuela"},
      {name: "United_Arab_Emirates"},
      {name: "United_States"},
      {name: "Vietnam"},
      {name: "Wallis_and_Futuna"},
      {name: "Christmas_Island"},
      {name: "Belarus"},
      {name: "Western_Sahara"},
      {name: "Central_African_Republic"},
      {name: "Cyprus"},
    ],
    dataProtectionIsAgreed: [],
    formValidationMessage: '',
    passwordResetIsNotAllowed: false,
    formData: {
      companyName: '',
      street: '',
      zip: null,
      city: '',
      country: '',
      phoneNumber: null,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      newsletterOptIn: [],
    },
    formRules: {
      firstName: [{
        message: 'modal.authentication.form.firstName.is_empty_text',
        validationFunction: value => value !== '' && value.length <= 256,
        trigger: 'submit',
      }],
      lastName: [{
        message: 'modal.authentication.form.lastName.is_empty_text',
        validationFunction: value => value !== '' && value.length <= 256,
        trigger: 'submit',
      }],
      street: [{
        message: 'modal.authentication.form.street.is_empty_text',
        validationFunction: value => value !== '' && value.length <= 256,
        trigger: 'submit',
      }],
      zip: [{
        message: 'modal.authentication.form.zip.is_empty_text',
        validationFunction: value => value !== null && value.length <= 256,
        trigger: 'submit',
      }],
      city: [{
        message: 'modal.authentication.form.city.is_empty_text',
        validationFunction: value => value !== '' && value.length <= 256,
        trigger: 'submit',
      }],
      country: [{
        message: 'modal.authentication.form.country.is_empty_text',
        validationFunction: value => value !== '' && value.length <= 256,
        trigger: 'submit',
      }],
      email: [{
        message: 'modal.authentication.form.email.is_empty_text',
        validationFunction: (value) => {
          const re = /^.+@.+\..+$/;

          return value !== '' && re.test(value) && value.length <= 256;
        },
        trigger: 'submit',
      }],
      password: [{
        message: 'modal.authentication.form.password.is_invalid_text',
        // validationFunction: value => value.length >= 8 && value.length <= 256,
        validationFunction: (value) => {
          const patternToTest = /(?=.*[a-z])(?=.*\d)(?=.*[@#$€%()[\]{}?!&/=*+~,.;:<>\-_"§`´'°^])/;
          const validatePattern = patternToTest.test(value);
          return value.length >= 8 && value.length <= 256 && validatePattern;
        },
        trigger: 'input',
      }],
      dataProtect: [{
        message: 'modal.authentication.form.dataProtect.is_empty_text',
        validationFunction: value => value,
        trigger: 'submit',
      }],
    },
  }),
  created() {
    if (!this.isResetPasswordSetPasswordType) return;

    // Handling password reset (setting a new password), checking the validity
    this.handleResetPasswordValidityCheck(this.$route.query.oobCode || '')
      .then((email) => {
        this.formData.email = email;
        this.updateAuthFormData(cloneDeep(this.formData));
      })
      .catch((error) => {
        this.passwordResetIsNotAllowed = true;
        this.formValidationMessage = error.message;
      });
  },
  watch: {
    formData: {
      deep: true,
      handler(newValue) {
        this.updateAuthFormData(cloneDeep(newValue));
      },
    },
  },
  computed: {
    ...mapGetters('ui', {
      modalTypeIsLogIn: 'modalTypeIsLogIn',
      modalTypeIsSignUp: 'modalTypeIsSignUp',
      modalTypeIsResetPasswordSendEmail: 'modalTypeIsResetPasswordSendEmail',
    }),
  },
  methods: {
    ...mapActions('ui', {
      updateAuthFormData: 'updateAuthFormData',
    }),
    ...mapActions('user', {
      logUserInWithEmailPassword: 'logUserInWithEmailPassword',
      signUserUpWithEmailPassword: 'signUserUpWithEmailPassword',
      sendPasswordResetEmail: 'sendPasswordResetEmail',
      handleResetPasswordValidityCheck: 'handleResetPasswordValidityCheck',
      handleResetPasswordAction: 'handleResetPasswordAction',
    }),
    resetFormValidationStatus() {
      this.$children.forEach((child) => {
        if (child.resetValidationStatus !== undefined) {
          child.resetValidationStatus();
        }
      });
    },
    async validateFormInputs() {
      const inputValidators = [];

      this.$children.forEach((child) => {
        if (child.validateInput !== undefined) {
          inputValidators.push(child.validateInput());
        }
      });

      return Promise.all(inputValidators)
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());
    },
    async onFormSubmission() {
      this.formValidationMessage = '';

      try {
        await this.validateFormInputs();

        if (this.modalTypeIsLogIn) {
          await this.logUserInWithEmailPassword();
        } else if (this.modalTypeIsSignUp) {
          if (this.dataProtectionIsAgreed.includes('dataProtectionIsAgreed')) {
            await this.signUserUpWithEmailPassword();
          } else {
            this.formValidationMessage = this.$t('modal.authentication.form.error_text');
            return;
          }
        } else if (this.modalTypeIsResetPasswordSendEmail) {
          await this.sendPasswordResetEmail(this.$i18n.locale);
        } else if (this.isResetPasswordSetPasswordType && !this.passwordResetIsNotAllowed) {
          await this.handleResetPasswordAction(this.$route.query.oobCode);

          await this.logUserInWithEmailPassword();

          this.$router.push({ name: 'HomeView' });
        }

        this.$emit('successfull-submission');
      } catch (error) {
        if (!error || !error.message) return;
        this.formValidationMessage = this.$t(`firebase_error_codes.${error.code}`);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
