import { render, staticRenderFns } from "./ViewLocale.vue?vue&type=template&id=06bd1eb6&scoped=true&"
import script from "./ViewLocale.vue?vue&type=script&lang=js&"
export * from "./ViewLocale.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06bd1eb6",
  null
  
)

export default component.exports