<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
  >
    <g
        class="stroke-current"
        stroke-width="1.5"
        fill="none"
        fill-rule="nonzero"
    >
      <path
          d="M10.746 18.378c.434-.793.683-1.71.683-2.688 0-1.089-.309-2.104-.84-2.954M14.434
          20.651a10.15 10.15 0 0 0 1.28-4.96c0-1.941-.537-3.751-1.465-5.279M17.985 22.876A14.14
          14.14 0 0 0 19.75 16c0-2.796-.8-5.398-2.177-7.57"
      />
    </g>
  </svg>

</template>


<script>
export default {};
</script>


<style lang="scss" scoped>
</style>
