var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-40 pt-40"},[_c('ul',{staticClass:"flex list-container list-reset bg-charcoal-grey"},_vm._l((_vm.stepOptions),function(stepOption,index){return _c('li',{key:("search-step-header-" + index),staticClass:"step p-23 focus:outline-none",class:{
            'bg-faded-red': index === _vm.activeStepIndex,
            'bg-charcoal-grey-two': index === _vm.hoverIndex && index !== _vm.activeStepIndex
                && (index <= _vm.activeStepIndex || _vm.allowedStepIndexes.includes(index)),
            'border-r border-dark-grey': _vm.rightBorderIsVisible(index,
            _vm.activeStepIndex, _vm.stepOptions.length),
            'w-1/2': _vm.stepOptions.length === 2,
            'w-1/3': _vm.stepOptions.length === 3,
            'cursor-pointer': (index <= _vm.activeStepIndex || _vm.allowedStepIndexes.includes(index))
                && index !== _vm.activeStepIndex,
            'cursor-not-allowed': _vm.isDisabled(index),
        },attrs:{"aria-checked":index === _vm.activeStepIndex,"aria-disabled":_vm.isDisabled(index),"tabindex":_vm.isDisabled(index) ? -1 : 0},on:{"click":function($event){return _vm.onStepItemPress(index)},"mouseover":function($event){_vm.hoverIndex = index},"mouseout":function($event){_vm.hoverIndex = -1},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.onStepItemPress(index)}}},[_c('div',{staticClass:"text-lg font-sans-bold leading-19 tracking-base text-pure-white"},[_vm._v(" "+_vm._s(("0" + (index + 1)))+" ")]),_c('h2',{staticClass:"font-sans-regular font-light text-lg text-pure-white leading-19 tracking-base\n          uppercase",attrs:{"title":("0" + (index + 1) + " - " + (_vm.$t(stepOption.stepTitle)))}},[_vm._v(" "+_vm._s(_vm.$t(stepOption.stepTitle))+" ")])])}),0),_c('div',{staticClass:"flex"},_vm._l((_vm.stepOptions),function(stepOption,index){return _c('div',{key:("step-details" + index),staticClass:"text-charcoal-grey",class:{
            'w-1/2': _vm.stepOptions.length === 2,
            'w-1/3': _vm.stepOptions.length === 3,
            'p-23': !stepOption.description.itemsIsVisible,
            'py-23': stepOption.description.itemsIsVisible,
        }},[(!stepOption.description.itemsIsVisible)?_c('p',{staticClass:"text-style-7",class:{ 'text-faded-red': index === _vm.activeStepIndex },attrs:{"title":_vm.$t(stepOption.description.placeholder)}},[_vm._v(" "+_vm._s(_vm.$t(stepOption.description.placeholder))+" ")]):_vm._l((stepOption.description.items),function(item,index){return (!item.itemIsHidden)?_c('div',{key:("selected-option-" + index),staticClass:"flex flex-row items-center"},[(item.iconComponent)?_c(item.iconComponent,{tag:"Component",staticClass:"flex-no-shrink w-30 h-30"}):(item.iconSrc)?_c('img',{staticClass:"flex-no-shrink",attrs:{"src":item.iconSrc,"alt":"Icon"}}):_vm._e(),_c('p',{staticClass:"text-style-7 ml-7 truncate",attrs:{"title":item.text}},[(_vm.isString(item.text))?_c('span',[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._l((item.text),function(text,index){return _c('span',{key:("label-sub-text-" + index),class:{ 'pr-31': index !== item.text.length - 1 }},[_vm._v(" "+_vm._s(text)+" ")])})],2)],1):_vm._e()})],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }