<template>
  <button
      class="text-center"
      @click="$emit(action)"
  >
    <span
        class="text-sm tracking-base"
        :class="{ 'is-disabled': isDisabled }"
    >
      <slot></slot>
    </span>
  </button>
</template>


<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: '',
    }
  },
};
</script>


<style lang="scss" scoped>
  .is-disabled {
    opacity: 0.7;
  }
</style>
