import { FBFirestoreRef } from '@/helpers/firebaseServicesHelper';
import { cloneDeep } from 'lodash';


export default {
  namespaced: true,
  state: {
    bookmarksListsIsInitialLoading: true,
    bookmarkedProductsIsInitialLoading: true,
    generalBookmarkList: {
      id: '',
      name: '',
      products: [],
    },
    bookmarkLists: [],
    bookmarkedProducts: [],
  },
  getters: {
    bookmarkListOptions: (state) => {
      if (state.bookmarksListsIsInitialLoading || state.bookmarkedProductsIsInitialLoading) {
        return null;
      }

      // Generate the bookmarkLists with the generalBookmarkList
      const bookmarkLists = [{
        id: state.generalBookmarkList.id,
        name: state.generalBookmarkList.name,
      }, ...state.bookmarkLists];
      const bookmarkListOptions = bookmarkLists
        .map(bookmarkList => ({ ...bookmarkList, products: [] }));

      state.bookmarkedProducts.forEach((product) => {
        product.data.bookmarkListIds.forEach((listId) => {
          const bookmarkListIndex = bookmarkLists
            .findIndex(bookmarkList => bookmarkList.id === listId);

          if (bookmarkListIndex !== -1) {
            bookmarkListOptions[bookmarkListIndex].products.push({
              id: product.id,
              data: product.data,
            });
          }
        });
      });

      return bookmarkListOptions;
    },
    selectedBookmarkListOption: (state, getters, rootState) => {
      if (!getters.bookmarkListOptions) return { name: '', products: [] };

      const selectedBookmarkListOption = getters.bookmarkListOptions
        .find(bookmarkListOption => bookmarkListOption.id === rootState.ui.selectedBookmarkListView.id);

      // If ID is not available (deleted) select the general list
      if (!selectedBookmarkListOption) {
        return { name: '', products: [] };
      }

      return selectedBookmarkListOption;
    },
  },
  mutations: {
    RESET_STATE(state) {
      state.bookmarksListsIsInitialLoading = true;
      state.bookmarkedProductsIsInitialLoading = true;
      state.bookmarkLists = [];
      state.bookmarkedProducts = [];
    },
    SET_BOOKMARK_LISTS_IS_INITIAL_LOADING(state, bookmarksListsIsInitialLoading) {
      state.bookmarksListsIsInitialLoading = bookmarksListsIsInitialLoading;
    },
    SET_BOOKMARKED_PRODUCTS_IS_INITIAL_LOADING(state, bookmarkedProductsIsInitialLoading) {
      state.bookmarkedProductsIsInitialLoading = bookmarkedProductsIsInitialLoading;
    },
    SET_GENERAL_BOOKMARK_LIST_NAME(state, generalBookmarkListName) {
      state.generalBookmarkList.name = generalBookmarkListName;
    },
    SET_BOOKMARK_LISTS(state, bookmarkLists) {
      state.bookmarkLists = bookmarkLists;
    },
    SET_BOOKMARKED_PRODUCTS(state, bookmarkedProducts) {
      state.bookmarkedProducts = bookmarkedProducts;
    },
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    updateGeneralBookmarkListName({ commit }, generalBookmarkListName) {
      commit('SET_GENERAL_BOOKMARK_LIST_NAME', generalBookmarkListName);
    },
    updateBookmarkLists({ state, commit }, bookmarkLists) {
      if (state.bookmarksListsIsInitialLoading) commit('SET_BOOKMARK_LISTS_IS_INITIAL_LOADING', false);
      commit('SET_BOOKMARK_LISTS', bookmarkLists);
    },
    updateBookmarkedProducts({ state, commit }, bookmarkedProducts) {
      if (state.bookmarkedProductsIsInitialLoading) commit('SET_BOOKMARKED_PRODUCTS_IS_INITIAL_LOADING', false);
      commit('SET_BOOKMARKED_PRODUCTS', bookmarkedProducts);
    },
    FSAddBookmarkListDocument: async ({ rootState, dispatch }) => {
      try {
        return FBFirestoreRef
          .collection('users')
          .doc(rootState.user.id)
          .collection('bookmarkLists')
          .add({ name: rootState.ui.bookmarkFormData.name })
          .then((docRef) => {
            dispatch('ui/updateBookmarkFormData', { name: '' }, { root: true });

            return Promise.resolve({
              id: docRef.id,
              name: rootState.ui.bookmarkFormData.name,
            });
          });
      } catch (e) {
        return Promise.reject(e);
      }
    },
    FSUpdateBookmarkListDocument: async ({ rootState, dispatch }) => {
      try {
        return FBFirestoreRef
          .collection('users')
          .doc(rootState.user.id)
          .collection('bookmarkLists')
          .doc(rootState.ui.selectedBookmarkListView.id)
          .update({ name: rootState.ui.bookmarkFormData.name })
          .then(() => {
            dispatch('ui/updateSelectedBookmarkListView', { id: rootState.ui.selectedBookmarkListView.id, name: rootState.ui.bookmarkFormData.name }, { root: true });
            dispatch('ui/updateBookmarkFormData', { name: '' }, { root: true });

            return Promise.resolve();
          });
      } catch (e) {
        return Promise.reject(e);
      }
    },
    FSRemoveEmptyBookmarkedProductDocument({ rootState }, bookmarkedProductId ) {
      try {
        FBFirestoreRef.collection('users').doc(rootState.user.id).collection('bookmarkedProducts').doc(bookmarkedProductId).onSnapshot((querySnapshot) => {
          const data = querySnapshot.data();
          if (!data.bookmarkListIds.length) {
            FBFirestoreRef
              .collection('users')
              .doc(rootState.user.id)
              .collection('bookmarkedProducts')
              .doc(bookmarkedProductId)
              .delete();
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    FSDeleteBookmarkListDocument: async ({ rootState, dispatch }, data ) => {
      try {
        const deleteBatch = FBFirestoreRef.batch();
        deleteBatch.delete(FBFirestoreRef
          .collection('users')
          .doc(rootState.user.id)
          .collection('bookmarkLists')
          .doc(data.listId));

        data.bookmarkedProductIds.forEach((bookmarkedProductId) => {
          FBFirestoreRef
            .collection('users')
            .doc(rootState.user.id)
            .collection('bookmarkedProducts')
            .doc(bookmarkedProductId)
            .update({
              'bookmarkListIds': FBFirestoreRef.app.firebase_.firestore.FieldValue.arrayRemove(data.listId)
            }).then(() => {
              dispatch('FSRemoveEmptyBookmarkedProductDocument', bookmarkedProductId);
            });
        });
        return deleteBatch.commit().then(() => Promise.resolve());
      } catch (e) {
        return Promise.reject(e);
      }
    },
    FSSetBookmarkedProductDocument: async ({ rootState, dispatch }, productData) => {
      try {
        const productDocumentId = productData.id;
        const productDocumentData = cloneDeep(productData);
        productDocumentData.bookmarkListIds = [];
        rootState.ui.selectedBookmarksLists.forEach(elem => productDocumentData.bookmarkListIds.push(elem.id));
        delete productDocumentData.id;
        if (productDocumentData.bookmarkListIds.length) {
          return FBFirestoreRef
          .collection('users')
          .doc(rootState.user.id)
          .collection('bookmarkedProducts')
          .doc(productDocumentId)
          .set(productDocumentData, { merge: true })
          .then(() => {
            dispatch('ui/updateBookmarkFormData', { name: '' }, { root: true });

            return Promise.resolve();
          });
        }

        const deleteBatch = FBFirestoreRef.batch();
        deleteBatch.delete(FBFirestoreRef
        .collection('users')
        .doc(rootState.user.id)
        .collection('bookmarkedProducts')
        .doc(productDocumentId));
        return deleteBatch.commit().then(() => Promise.resolve());
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
