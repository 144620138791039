<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
  >
    <path
        class="stroke-current"
        stroke-width="1.5"
        fill="none"
        fill-rule="nonzero"
        d="M13.009 22.67c-2.136-.666-3.602-1.865-3.923-3.596-.49-2.637 1.259-4.719
        1.598-7.611.627.785 1.017 1.361.759 2.457.896-1.672 2.077-2.695 1.777-6.92 5.695 2.213 9.974
        13.076 2.76 15.67.935-2.61.935-4.66-.852-6.375-1.239 2.198-4.37 2.779-3.119 6.375z"
    />
  </svg>
</template>


<script>
export default {
};
</script>


<style lang="scss" scoped>
</style>
