<template>
  <label
      role="radio"
      :aria-checked="isSelected"
      :tabindex="0"
      :style="isSelected ? activeStyle : null"
      class="ui-radio-button py-12 text-center cursor-pointer focus:outline-none"
      :class="{ 'is-selected': isSelected }"
      @keydown.space.stop.prevent="radioGroup.$emit('input', val)"
  >
    <input
        v-bind="$attrs"
        type="radio"
        v-model="value"
        tabindex="-1"
        class="absolute opacity-0"
    >
    <span
        class="label inline-block align-middle font-sans-bold text-sm
        cursor-pointer select-none"
        :class="{
            'text-pure-white': isSelected,
            'text-charcoal-grey': !isSelected,
        }"
    >
      {{ label }}
      <sub v-if="subLabel">
        {{ subLabel }}
      </sub>
    </span>
  </label>
</template>


<script>
import { colors } from '@/../tailwind';


export default {
  inheritAttrs: false,
  props: {
    val: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '-',
    },
    subLabel: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    fillColor: '#3b3c40',
  }),
  computed: {
    value: {
      get() {
        return this.radioGroup.value;
      },
      set() {
        this.radioGroup.$emit('input', this.val);
      },
    },
    isSelected() {
      return this.value === this.val;
    },
    radioGroup() {
      return this.$parent;
    },
    activeStyle() {
      return {
        borderColor: colors['charcoal-grey'],
      };
    },
  },
};
</script>


<style lang="scss" scoped>
.ui-radio-button.is-selected {
  @apply bg-charcoal-grey;
}

@media (any-pointer: fine) {
  .ui-radio-button:not(.is-selected) {
    // Show focus state only if the device has a pointing device
    &:focus .label,
    &:hover .label {
      @apply text-faded-red;
    }
  }
}
</style>
