<template>
  <div class="home flex-1 relative">
    <div class="absolute w-full h-full flex flex-col">
      <div class="main-background flex-grow bg-cover bg-no-repeat bg-center"></div>
      <div class="spacer"></div>
    </div>
    <div class="absolute pin-t w-full h-full p-40 flex flex-col justify-between">
      <div>
        <p class="text-base text-pure-white text-shadow-strong py-12 tablet-portrait:pt-32">
          {{ $t('home.welcome_text') }}
        </p>
        <h2 class="font-sans-bold text-2xl text-pure-white text-shadow-base leading-tight
        tracking-base uppercase whitespace-pre">{{ $t('home.main_text') }}</h2>
      </div>
      <div class="absolute pin-x pin-b p-40 flex flex-col-reverse
      items-end tablet-landscape:flex-row">
        <div class="flex-grow flex w-full h-210 tablet-landscape:mr-20 bg-faded-red shadow">
          <div class="flex-grow flex-shrink relative
          flex flex-col justify-between p-31 pt-24">
            <p class="font-sans-bold text-lg text-pure-white">
              {{ $t('home.predefined_search.main_text') }}
            </p>
            <RegionSelector />
          </div>
          <div class="flex flex-col flex-min-220 justify-between
          pb-31 pt-24 pl-31 pr-26 bg-faded-red-two">
            <p class="font-sans-bold text-lg text-pure-white">
              {{ $t('home.manual_search.main_text') }}
            </p>
            <RouterLink
                :to="{ name: 'AssemblySearchView', params: {
                    locale: $i18n.locale,
                    searchType: 'manual',
                } }"
                tag="div"
                class="flex justify-end font-sans-regular text-base tracking-tight text-pure-white
                cursor-pointer"
            >
              <p class="pr-11">{{ $t('home.manual_search.link_text') }}</p>
              <IconArrow class="text-pure-white cursor-pointer" />
            </RouterLink>
          </div>
        </div>
        <div
            v-show="!userIsAuthenticated"
            class="registration flex flex-col justify-between w-220 h-210
            bg-pure-white p-31 pt-24 mb-20 tablet-landscape:m-0 shadow"
        >
          <p class="text-style-3">
            {{ $t('home.registration.main_text') }}
          </p>
          <div
              class="flex justify-end"
              @click="onRegistrationPress"
          >
            <p class="pr-11 text-base cursor-pointer">
              {{ $t('home.registration.link_text') }}
            </p>
            <IconArrow class="text-faded-red cursor-pointer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import RegionSelector from '@/components/RegionSelector';
import IconArrow from '@/components/icon/IconArrow';


export default {
  components: {
    RegionSelector,
    IconArrow,
  },
  computed: {
    ...mapGetters('user', {
      userIsAuthenticated: 'isAuthenticated',
    }),
  },
  methods: {
    ...mapActions('ui', {
      updateModalState: 'updateModalState',
    }),
    onRegistrationPress() {
      this.updateModalState({ isVisible: true, type: 'sign-up' });
    },
  },
};
</script>


<style lang="scss" scoped>
.flex-min-210 {
  flex: 0 0 210px;
}

.flex-min-220 {
  flex: 0 0 220px;
}

.text-shadow-strong {
  text-shadow: 0 2px 20px rgba(0, 0, 0, 1);
}

.text-shadow-base {
  text-shadow: 0 2px 30px rgba(0, 0, 0, 0.8);
}

.home {
  .main-background {
    @apply bg-white;

    background-image: url('/images/home-background-v1.jpg');
    filter: brightness(90%);
  }

  .spacer {
    flex: 0 0 146px;
  }

  .registration {
    @apply flex-min-210;
  }
}

@screen tablet-landscape {
  .home .registration {
    @apply flex-min-220;
  }
}
</style>
