<template>
  <UiModal 
    :is-visible.sync="modalIsVisible"
    :is-close-area="isCloseArea"
    >
    <template slot="header">
      <h3 class="font-sans-bold text-base text-faded-red">
        <template v-if="successMessageIsVisible">
          {{ $t(`modal.authentication.${activeModalTypeTranslationKey}.success.title_text`) }}
        </template>
        <template v-else>
          {{ modalOptions.title }}
        </template>
      </h3>
    </template>
    <Transition
        name="fade-in-linear"
        type="out-in"
    >
      <div v-if="successMessageIsVisible">
        <IconCheck
            :width="60"
            :height="60"
            class="block text-emerald"
        />
        <p class="font-sans-bold text-xl text-charcoal-grey uppercase mb-40">
          {{ $t(`modal.authentication.${activeModalTypeTranslationKey}.success.main_text`, {
          userName: userFirstName,
          }) }}
        </p>
        <p class="text-style-4">
          {{ $t(`modal.authentication.${activeModalTypeTranslationKey}.success.description_text`) }}
        </p>
        <p class="mt-20">
          <IconInfo
              is-active
              :size="31"
              class="align-bottom -ml-3"
          />
          <span class="text-style-4 pl-5">
            {{ $t(`modal.authentication.login_register_success.first_part_text`) }}
            <span class="font-sans-bold">
              {{ $t(`modal.authentication.login_register_success.second_part_text`) }}
            </span>
            {{ $t(`modal.authentication.login_register_success.third_part_text`) }}
          </span>
        </p>
      </div>
      <div v-else>
        <div
            v-if="modalTypeIsResetPasswordSendEmail"
            class="inline-flex items-center mb-18 cursor-pointer"
            @click="onBackToLoginPress"
            @keydown.space.native.stop="onBackToLoginPress"
        >
          <IconArrow
              direction="left"
              class="text-faded-red"
          />
          <p class="text-style-7 ml-10">
            {{ $t('modal.authentication.reset_password_send_email.back_to_login_text') }}
          </p>
        </div>
        <p class="text-style-7 mb-35">
          {{ modalOptions.description }}
        </p>
        <AuthForm
            ref="auth-form"
            :submit-button-label="modalOptions.buttonLabel"
            :class="{ 'mb-25': modalTypeIsLogIn }"
            @successfull-submission="successMessageIsVisible = true"
        />
        <p
            v-if="modalTypeIsLogIn"
            class="text-sm text-charcoal-grey"
        >
          {{ $t('modal.authentication.login.no_account_yet_text') }}
          <span
              tabindex="0"
              class="text-faded-red cursor-pointer"
              @click="onRegistrationPress"
              @keydown.enter.stop.prevent="onRegistrationPress"
          >
          {{ $t('modal.authentication.login.register_here_text') }}
        </span>
        <span
            tabindex="0"
            class="block mt-12 text-faded-red cursor-pointer"
            @click="onResetPasswordPress"
            @keydown.enter.stop.prevent="onResetPasswordPress"
        >
          {{ $t('modal.authentication.login.forgot_password_text') }}
        </span>
        </p>
      </div>
    </Transition>
  </UiModal>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import UiModal from '@/components/ui/UiModal';
import IconArrow from '@/components/icon/IconArrow';
import IconInfo from '@/components/icon/IconInfo';
import IconCheck from '@/components/icon/IconCheck';
import AuthForm from '@/components/auth/AuthForm';


export default {
  components: {
    UiModal,
    IconArrow,
    IconInfo,
    IconCheck,
    AuthForm,
  },
  data: () => ({
    modalIsVisible: false,
    successMessageIsVisible: false,
    formValidationMessage: '',
    isCloseArea: true,
  }),
  watch: {
    'uiModal.isVisible': {
      immediate: true,
      handler(newValue) {
        if (!this.modalTypeIsLogIn
          && !this.modalTypeIsSignUp
          && !this.modalTypeIsResetPasswordSendEmail) return;
        this.modalIsVisible = newValue;
      },
    },
    modalIsVisible: {
      handler(newValue) {
        if (!newValue) this.successMessageIsVisible = false;
        this.updateModalState({ isVisible: newValue });
      },
    },
  },
  computed: {
    ...mapState('user', {
      userFirstName: 'firstName',
    }),
    ...mapState('ui', {
      uiModal: 'modal',
    }),
    ...mapGetters('ui', {
      modalTypeIsLogIn: 'modalTypeIsLogIn',
      modalTypeIsSignUp: 'modalTypeIsSignUp',
      modalTypeIsResetPasswordSendEmail: 'modalTypeIsResetPasswordSendEmail',
    }),
    activeModalTypeTranslationKey() {
      if (this.modalTypeIsLogIn) return 'login';
      if (this.modalTypeIsSignUp) return 'register';
      return 'reset_password_send_email';
    },
    modalOptions() {
      return {
        title: this.$t(`modal.authentication.${this.activeModalTypeTranslationKey}.title_text`),
        description: this.$t(`modal.authentication.${this.activeModalTypeTranslationKey}.description_text`),
        buttonLabel: this.$t(`modal.authentication.${this.activeModalTypeTranslationKey}.button_text`),
      };
    },
  },
  methods: {
    ...mapActions('ui', {
      updateModalState: 'updateModalState',
    }),
    onRegistrationPress() {
      this.$refs['auth-form'].resetFormValidationStatus();
      this.updateModalState({ isVisible: true, type: 'sign-up' });
    },
    onResetPasswordPress() {
      this.$refs['auth-form'].resetFormValidationStatus();
      this.updateModalState({ isVisible: true, type: 'reset-password-send-email' });
    },
    onBackToLoginPress() {
      this.$refs['auth-form'].resetFormValidationStatus();
      this.updateModalState({ isVisible: true, type: 'log-in' });
    },
  },
};
</script>


<style lang="scss" scoped>

</style>
