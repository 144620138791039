<template>
  <svg
      :style="style"
      xmlns="http://www.w3.org/2000/svg"
      :width="size"
      :height="size"
      viewBox="0 0 20 20"
  >
    <g
        class="stroke-current"
        stroke-width="3"
        fill="none"
        fill-rule="nonzero"
    >
      <path d="M4.6 4.6l10.866 10.866M15.4 4.6L4.534 15.466" />
    </g>
  </svg>
</template>


<script>
export default {
  props: {
    size: {
      type: Number,
      default: 20,
    },
    rotationAngle: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    style() {
      return { transform: `rotateZ(${this.rotationAngle}deg)` };
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
