<template>
  <svg
      :style="style"
      xmlns="http://www.w3.org/2000/svg"
      :width="size"
      :height="size"
      viewBox="0 0 20 20"
  >
    <path
        class="stroke-current"
        :stroke-width="strokeWidth"
        fill="none"
        fill-rule="nonzero"
        d="M7 5l5 5-5 5"
    />
  </svg>
</template>


<script>
export default {
  props: {
    size: {
      type: Number,
      default: 20,
    },
    strokeWidth: {
      type: Number,
      default: 3,
    },
    direction: {
      type: String,
      validator(value) {
        return ['up', 'right', 'down', 'left'].includes(value);
      },
      default: 'right',
    },
  },
  computed: {
    style() {
      let rotation = 0;

      if (this.direction === 'up') rotation = -90;
      else if (this.direction === 'down') rotation = 90;
      else if (this.direction === 'left') rotation = 180;

      return { transform: `rotateZ(${rotation}deg)` };
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
