<template>
  <div
      id="app"
      class="h-full"
  >
    <RouterView />
  </div>
</template>


<script>
import { mapActions } from 'vuex';


export default {
  watch: {
    '$route.params.locale': {
      immediate: true,
      handler() {
        // Sync VueX translations
        this.$store.dispatch('user/bookmarks/updateGeneralBookmarkListName', this.$t('bookmarks.general_list_text'));
      },
    },
  },
  created() {
    // Getting the DB Values for the Search UI components (preloading)
    this.updateDBValues();
  },
  methods: {
    ...mapActions('assemblySearch', {
      updateDBValues: 'updateDBValues',
    }),
  },
};
</script>


<style lang="scss" scoped>
</style>
