<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="size"
      :height="size"
      viewBox="0 0 30 30"
  >
    <!-- eslint-disable -->
    <path
        class="stroke-current"
        stroke-width="1.5"
        fill="none"
        fill-rule="nonzero"
        d="M15.5 23.333C11.167 18.255 9 14.563 9 12.26 9 8.802 11.91 6 15.5 6S22 8.802 22 12.26c0 2.304-2.167 5.995-6.5 11.073zm.144-7.8a3.033 3.033 0 1 0 0-6.066 3.033 3.033 0 0 0 0 6.066z"/>
    <!-- eslint-enable -->
  </svg>
</template>


<script>
export default {
  props: {
    size: {
      type: Number,
      default: 30,
    },
  },
};
</script>


<style lang="scss" scoped>

</style>
