<template>
  <div class="px-40 pt-40">
    <ul class="flex list-container list-reset bg-charcoal-grey">
      <li
          v-for="(stepOption, index) in stepOptions"
          :key="`search-step-header-${index}`"
          :aria-checked="index === activeStepIndex"
          :aria-disabled="isDisabled(index)"
          :tabindex="isDisabled(index) ? -1 : 0"
          class="step p-23 focus:outline-none"
          :class="{
              'bg-faded-red': index === activeStepIndex,
              'bg-charcoal-grey-two': index === hoverIndex && index !== activeStepIndex
                  && (index <= activeStepIndex || allowedStepIndexes.includes(index)),
              'border-r border-dark-grey': rightBorderIsVisible(index,
              activeStepIndex, stepOptions.length),
              'w-1/2': stepOptions.length === 2,
              'w-1/3': stepOptions.length === 3,
              'cursor-pointer': (index <= activeStepIndex || allowedStepIndexes.includes(index))
                  && index !== activeStepIndex,
              'cursor-not-allowed': isDisabled(index),
          }"
          @click="onStepItemPress(index)"
          @mouseover="hoverIndex = index"
          @mouseout="hoverIndex = -1"
          @keydown.space.stop.prevent="onStepItemPress(index)"
      >
        <div class="text-lg font-sans-bold leading-19 tracking-base text-pure-white">
          {{ `0${index + 1}` }}
        </div>
        <h2
            :title="`0${index + 1} - ${$t(stepOption.stepTitle)}`"
            class="font-sans-regular font-light text-lg text-pure-white leading-19 tracking-base
            uppercase"
        >
          {{ $t(stepOption.stepTitle) }}
        </h2>
      </li>
    </ul>
    <div class="flex">
      <div
          v-for="(stepOption, index) in stepOptions"
          :key="`step-details${index}`"
          class="text-charcoal-grey"
          :class="{
              'w-1/2': stepOptions.length === 2,
              'w-1/3': stepOptions.length === 3,
              'p-23': !stepOption.description.itemsIsVisible,
              'py-23': stepOption.description.itemsIsVisible,
          }"
      >
        <p
            v-if="!stepOption.description.itemsIsVisible"
            :title="$t(stepOption.description.placeholder)"
            class="text-style-7"
            :class="{ 'text-faded-red': index === activeStepIndex }"
        >
          {{ $t(stepOption.description.placeholder) }}
        </p>
        <template
            v-else
        >
          <div
              v-if="!item.itemIsHidden"
              v-for="(item, index) in stepOption.description.items"
              :key="`selected-option-${index}`"
              class="flex flex-row items-center"
          >
            <Component
                v-if="item.iconComponent"
                :is="item.iconComponent"
                class="flex-no-shrink w-30 h-30"
            />
            <img
                v-else-if="item.iconSrc"
                :src="item.iconSrc"
                alt="Icon"
                class="flex-no-shrink"
            />
            <p
                :title="item.text"
                class="text-style-7 ml-7 truncate"
            >
            <span v-if="isString(item.text)">
              {{ item.text }}
            </span>
              <span
                  v-else
                  v-for="(text, index) in item.text"
                  :key="`label-sub-text-${index}`"
                  :class="{ 'pr-31': index !== item.text.length - 1 }"
              >
              {{ text }}
            </span>
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { isString } from 'lodash';
import IconLocation from '@/components/icon/IconLocation';
import IconBuilding from '@/components/icon/IconBuilding';
import IconSoundWave from '@/components/icon/IconSoundWave';
import IconFireFlame from '@/components/icon/IconFireFlame';
import assemblyTypeImages from '@/helpers/assemblyTypeHelper';
import { rightBorderIsVisibleForItemIndex } from '@/helpers/uiItemsHelper';


export default {
  components: {
    IconLocation,
    IconBuilding,
    IconSoundWave,
    IconFireFlame,
  },
  data: () => ({
    baseUrl: process.env.BASE_URL,
    hoverIndex: -1,
  }),
  computed: {
    ...mapState('assemblySearch', {
      activeStepIndex: 'activeStepIndex',
      searchType: 'searchType',
      DBValues: 'DBValues',
      selectedValues: 'selectedValues',
      selectedSpecificationValues: 'selectedSpecificationValues',
    }),
    ...mapGetters('assemblySearch', {
      searchTypeIsManual: 'searchTypeIsManual',
      searchTypeIsPreset: 'searchTypeIsPreset',
      allowedStepIndexes: 'allowedStepIndexes',
    }),
    stepOptions() {
      const stepOptions = [{
        stepTitle: 'constructions_search.steps.first_step.main_text',
        description: {
          placeholder: 'constructions_search.steps.first_step.helper_text',
          items: [{
            iconComponent: IconLocation,
            text: this.regionLabel,
          }, {
            iconComponent: IconBuilding,
            text: this.buildingTypeAndClassLabel,
          }],
          itemsIsVisible: this.regionLabel !== '' && this.buildingTypeAndClassLabel !== '' && this.activeStepIndex !== 0,
        },
        isVisible: !this.searchTypeIsManual,
      }, {
        stepTitle: 'constructions_search.steps.second_step.main_text',
        description: {
          placeholder: 'constructions_search.steps.second_step.helper_text',
          items: [{
            iconSrc: `${this.baseUrl}images/icons/assembly-types/small/${assemblyTypeImages[this.selectedValues.assemblyType]}.svg`,
            text: this.assemblyTypeLabel,
          }],
          itemsIsVisible: this.assemblyTypeLabel !== '' && ((this.activeStepIndex >= 2 && this.searchTypeIsPreset)
            || (this.activeStepIndex >= 1 && this.searchTypeIsManual)),
        },
        isVisible: true,
      }, {
        stepTitle: 'constructions_search.steps.third_step.main_text',
        description: {
          placeholder: 'constructions_search.steps.third_step.helper_text',
          items: [{
            iconComponent: IconSoundWave,
            text: this.adaptationLabel,
          }, {
            iconComponent: IconFireFlame,
            text: this.fireResistancesLabel,
            itemIsHidden: this.fireResistancesLabel === '',
          }],
          itemsIsVisible: this.assemblyTypeLabel !== '' && ((this.activeStepIndex >= 3 && this.searchTypeIsPreset)
            || (this.activeStepIndex >= 2 && this.searchTypeIsManual)),
        },
        isVisible: true,
      }];

      return stepOptions.filter(item => item.isVisible);
    },
    regionLabel() {
      try {
        return this.DBValues.regions
          .find(region => region.value === this.selectedValues.region).data[this.$i18n.locale].name;
      } catch (e) {
        return '';
      }
    },
    buildingTypeAndClassLabel() {
      try {
        const buildingTypeLabel = this.DBValues.buildingTypes
          .find(type => type.value === this.selectedValues.buildingType)
          .data[this.$i18n.locale].name;
        const buildingClassLabel = this.DBValues.buildingClasses
          .find(type => type.value === this.selectedValues.buildingClass)
          .data[this.$i18n.locale].abbreviation;

        if (!buildingTypeLabel || !buildingClassLabel) return '';
        return `${buildingTypeLabel}, ${buildingClassLabel}`;
      } catch (e) {
        return '';
      }
    },
    assemblyTypeLabel() {
      try {
        return this.DBValues.assemblyTypes
          .find(region => region.value === this.selectedValues.assemblyType)
          .data[this.$i18n.locale].name;
      } catch (e) {
        return '';
      }
    },
    adaptationLabel() {
      if (this.selectedSpecificationValues.lnwAdaptation.range[0] === 0
        && this.selectedSpecificationValues.lnwAdaptation.range[1] === 0) {
        return `Rw  ${this.selectedSpecificationValues.rwAdaptation.range[0]} - ${this.selectedSpecificationValues.rwAdaptation.range[1]} dB`;
      }
      return [
        `Rw  ${this.selectedSpecificationValues.rwAdaptation.range[0]} - ${this.selectedSpecificationValues.rwAdaptation.range[1]} dB`,
        `Ln,w  ${this.selectedSpecificationValues.lnwAdaptation.range[0]} - ${this.selectedSpecificationValues.lnwAdaptation.range[1]} dB`,
      ];
    },
    fireResistancesLabel() {
      if (!this.selectedSpecificationValues.fireResistances.length) return '';
      return this.selectedSpecificationValues.fireResistances.join(', ');
    },
  },
  methods: {
    ...mapActions('assemblySearch', {
      updateActiveStepIndex: 'updateActiveStepIndex',
    }),
    isDisabled(index) {
      return index > this.activeStepIndex && !this.allowedStepIndexes.includes(index);
    },
    onStepItemPress(stepIndex) {
      if (this.$route.name === 'ProductSearchResultsView') {
        this.updateActiveStepIndex(stepIndex);
        this.$router.push({
          name: 'AssemblySearchView',
          params: { searchType: this.searchType, noReset: true },
          // props: { noReset: true },
        });
        return;
      }
      if (!this.allowedStepIndexes.includes(stepIndex)) return;

      this.updateActiveStepIndex(stepIndex);
    },
    rightBorderIsVisible(itemIndex, activeItemIndex, itemCount) {
      // if ((this.searchTypeIsManual && this.activeStepIndex >= 2)
      //   || (this.searchTypeIsPreset && this.activeStepIndex >= 3)) return true;
      // if (this.activeStepIndex !== 0
      //   && !this.allowedStepIndexes.includes(this.activeStepIndex - 1)) return true;
      return rightBorderIsVisibleForItemIndex(itemIndex, activeItemIndex, itemCount);
    },
    isString(value) {
      return isString(value);
    },
  },
};
</script>


<style lang="scss" scoped>
.step {
  transition: background-color 0.3s;
}
</style>
