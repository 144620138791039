import Vue from 'vue';
import Router from 'vue-router';
import { defaultLocale } from '@/i18n';
import BaseLayout from '@/layouts/BaseLayout';
import ViewLocale from '@/components/view/ViewLocale';
import HomeView from '@/views/HomeView';
import AssemblySearchView from '@/views/AssemblySearchView';

Vue.use(Router);


export default [{
  path: '/',
  redirect: `/${defaultLocale}`,
  component: BaseLayout,
  children: [{
    path: '/:locale',
    component: ViewLocale,
    children: [{
      path: '',
      name: 'HomeView',
      component: HomeView,
      meta: { viewTitle: 'navigation.view_titles.home_text' },
    }, {
      path: 'assembly-search/:searchType',
      name: 'AssemblySearchView',
      component: AssemblySearchView,
      meta: {
        viewTitles: {
          manual: 'navigation.view_titles.assembly_search.manual_text',
          preset: 'navigation.view_titles.assembly_search.preset_text',
        },
      },
    }, {
      path: 'password-reset',
      name: 'PasswordResetView',
      component: () => import('@/views/PasswordResetView'),
      meta: { viewTitle: 'navigation.view_titles.reset_password_text' },
    }, {
      path: 'impressum',
      name: 'ImpressumView',
      component: () => import('@/views/ImpressumView'),
      meta: { viewTitle: 'navigation.view_titles.impressum_text' },
    }, {
      path: 'data-privacy',
      name: 'DataPrivacyView',
      component: () => import('@/views/DataPrivacyView'),
      meta: { viewTitle: 'navigation.view_titles.data_privacy_text' },
    }, {
      path: 'tools',
      name: 'ToolsView',
      component: () => import('@/views/ToolsView'),
      meta: { viewTitle: 'navigation.view_titles.tools_text' },
    }, {
      path: 'product-details/:productType/:productId',
      name: 'ProductDetailsView',
      component: () => import('@/views/ProductDetailsView'),
      meta: { viewTitle: 'navigation.view_titles.product_detail_text' },
    }, {
      path: 'product-search-results',
      name: 'ProductSearchResultsView',
      component: () => import('@/views/ProductSearchResultsView'),
      meta: { viewTitle: 'Search Results' },
    }, {
      path: 'bookmarks',
      name: 'BookmarksView',
      component: () => import('@/views/BookmarksView'),
      meta: { viewTitle: 'navigation.view_titles.bookmarks_text', hasNoFooter: true, requiresAuth: true },
    }, {
      path: 'test',
      name: 'TestView',
      component: () => import('@/views/TestView'),
      meta: { viewTitle: 'Test' },
    }, {
      path: '*',
      redirect: '/',
    }],
  }],
}];
