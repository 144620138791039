import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import assemblySearch from './modules/assemblySearch';
import productDetails from './modules/productDetails';
import ui from './modules/ui';
import user from './modules/user';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    assemblySearch,
    productDetails,
    ui,
    user,
  },
  plugins: [createPersistedState({
    paths: [
      'assemblySearch.activeStepIndex',
      'assemblySearch.searchType',
      'assemblySearch.DBValues',
      'assemblySearch.selectedValues',
      'assemblySearch.selectedSpecificationValues',
      'ui.selectedBookmarkList',
    ],
  })],
});


export default store;
