/* eslint-disable no-empty-pattern */
import { FBAuth, getUser } from '@/helpers/firebaseServicesHelper';
import bookmarks from './bookmarks';


export default {
  namespaced: true,
  modules: {
    bookmarks,
  },
  state: {
    isInitialLoading: true,
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    companyName: '',
    street: '',
    zip: '',
    city: '',
    country: '',
    phone: '',
    emailIsVerified: false,
    companyName: '',
    street: '',
    zip: null,
    city: '',
    country: '',
    phoneNumber: null,
    newsletterOptIn: false,
  },
  getters: {
    isAuthenticated: state => state.firstName !== '',
  },
  mutations: {
    RESET_STATE(state) {
      state.id = null;
      state.firstName = '';
      state.lastName = '';
      state.email = '';
      state.password = '';
      state.companyName = '';
      state.street = '';
      state.zip = '';
      state.city = '';
      state.country = '';
      state.phone = '';
      state.emailIsVerified = false;
    },
    SET_USER_IS_INITIAL_LOADING(state, isInitialLoading) {
      state.isInitialLoading = isInitialLoading;
    },
    SET_USER(state, user) {
      let newDisplayName
      if (!user.displayName) {
        const characterMumber = user.email.indexOf('@')
        newDisplayName = user.email.substring(0, characterMumber)
      }
      state.id = user.uid || state.id;
      state.firstName = user.firstName || user.displayName || state.firstName || newDisplayName;
      state.lastName = user.lastName || state.lastName;
      state.email = user.email || state.email;
      state.companyName = user.companyName || state.companyName;
      state.street = user.street || state.street;
      state.zip = user.zip || state.zip;
      state.city = user.city || state.city;
      state.country = user.country || state.country;
      state.phone = user.phone || state.phone;
      state.emailIsVerified = user.emailVerified || state.emailIsVerified;
    },
    UPDATE_USER_DATA(state, userData) {
      state.firstName = userData.firstName || state.firstName;
      state.lastName = userData.lastName || state.lastName;
      state.companyName = userData.companyName || state.companyName;
      state.street = userData.street || state.street;
      state.zip = userData.zip || state.zip;
      state.country = userData.country || state.country;
      state.city = userData.city || state.city;
      state.phoneNumber = userData.phoneNumber || state.phoneNumber;
      state.newsletterOptIn = userData.newsletterOptIn || state.newsletterOptIn;
    },
  },
  actions: {
    resetState({ commit, dispatch }) {
      commit('RESET_STATE');

      dispatch('bookmarks/resetState', { root: true });
    },
    updateIsInitialLoading({ commit }, isInitialLoading) {
      commit('SET_USER_IS_INITIAL_LOADING', isInitialLoading);
    },
    async updateUser({ commit }, user) {
      commit('SET_USER', user);

      return Promise.resolve();
    },
    logUserInWithEmailPassword({ rootState, commit }) {
      return FBAuth.signInWithEmailAndPassword(rootState.ui.authFormData.email,
        rootState.ui.authFormData.password)
        .then((result) => {
          commit('SET_USER', result.user);

          return Promise.resolve(result.user);
        })
        .catch(error => Promise.reject(error));
    },
    signUserUpWithEmailPassword({ rootState, commit, dispatch }) {
      return FBAuth.createUserWithEmailAndPassword(rootState.ui.authFormData.email,
        rootState.ui.authFormData.password)
        .then((result) => {
          result.user.updateProfile({
            displayName: `${rootState.ui.authFormData.firstName} ${rootState.ui.authFormData.lastName}`
          })
          commit('SET_USER', result.user);
          dispatch('updateUserAccount', { firstName: rootState.ui.authFormData.firstName });
          dispatch('updateUserData', { 
            firstName: rootState.ui.authFormData.firstName,
            lastName: rootState.ui.authFormData.lastName,
            companyName: rootState.ui.authFormData.companyName,
            street: rootState.ui.authFormData.street,
            zip: rootState.ui.authFormData.zip,
            country: rootState.ui.authFormData.country,
            city: rootState.ui.authFormData.city,
            phoneNumber: rootState.ui.authFormData.phoneNumber,
            newsletterOptIn: !!rootState.ui.authFormData.newsletterOptIn.length,
          });
          return Promise.resolve(result.user);
        })
        .catch(error => Promise.reject(error));
    },
    logUserOut({ dispatch }) {
      return FBAuth.signOut()
        .then((response) => {
          Promise.all([
            dispatch('resetState'),
            // dispatch('', null, { root: true }),
          ]).then(() => Promise.resolve(response));
        }).catch(error => Promise.reject(error));
    },
    sendPasswordResetEmail({ rootState }, languageCode = 'de') {
      FBAuth.languageCode = languageCode;

      return FBAuth.sendPasswordResetEmail(rootState.ui.authFormData.email)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error));
    },
    updateUserAccount({ commit }, { firstName, lastName, companyName, street, zip, city, country, phone }) {
      return FBAuth.currentUser.updateProfile({
        firstName,
        lastName,
        companyName,
        street, 
        zip, 
        city, 
        country, 
        phone 
      })
        .then((response) => {
          commit('SET_USER', { firstName, lastName, companyName, street, zip, city, country, phone });
    
          return Promise.resolve(response);
        })
        .catch(error => Promise.reject(error));
    },
    handleResetPasswordValidityCheck({}, actionCode) {
      // Verify the validity of the password reset code.
      return FBAuth.verifyPasswordResetCode(actionCode)
        .then(email => Promise.resolve(email))
        // Invalid or expired action code. Try to reset the password again.
        .catch(error => Promise.reject(error));
    },
    handleResetPasswordAction({ rootState }, actionCode) {
      return FBAuth.confirmPasswordReset(actionCode, rootState.ui.authFormData.password)
        .then(response => Promise.resolve(response))
        // Error encountered while sending password reset code.
        .catch(error => Promise.reject(error));
    },
    updateUserData({ rootState, commit }, userData) {
      console.log(FBAuth.currentUser.uid);
      return getUser(FBAuth.currentUser.uid)
      .set({
        ...userData,
      }, { merge: true })
      .catch((error) => {
        console.error(error);
      })
      .then((response) => {
        commit('UPDATE_USER_DATA', userData);

        return Promise.resolve(response);
      })
      .catch(error => Promise.reject(error));
    },
  },
};
