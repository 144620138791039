<template>
  <div class="relative">
    <input
        v-bind="$attrs"
        :value="value"
        :tabindex="isDisabled ? -1 : 0"
        :disabled="isDisabled"
        :placeholder="placeholder"
        :type="type"
        class="text-input w-full h-50 px-18 text-style-7 bg-pure-white border border-pinkish-grey
        outline-none"
        :class="[type, {
            'is-empty': value === '',
            'text-faded-red': validationMessageIsVisible,
            'pr-47': invalidIconIsVisible || validIconIsVisible,
        }]"
        @input="$emit('input', $event.target.value)"
        @change="$emit('change', value)"
    >
    <Transition name="fade-in-linear">
      <IconCross
          v-if="invalidIconIsVisible"
          class="icon absolute text-faded-red"
      />
      <IconCheck
          v-else-if="validIconIsVisible"
          class="icon absolute text-emerald"
      />
    </Transition>
    <TransitionCollapse>
      <p
          v-if="validationMessageIsVisible"
          class="mt-4 text-sm text-faded-red"
          :class="{ 'mb-13': validationStatus === 'failed' }"
      >
        {{ validationMessage }}
      </p>
    </TransitionCollapse>
  </div>
</template>


<script>
import TransitionCollapse from '@/components/transition/TransitionCollapse';
import IconCross from '@/components/icon/IconCross';
import IconCheck from '@/components/icon/IconCheck';


const inputDelay = 1000;


export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    noIcons: {
      type: Boolean,
      default: false,
    },
    noSuccessIcon: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
      validator(value) {
        for (let i = 0; i < value.length; i += 1) {
          if (!value[i].message
            || value[i].validationFunction === undefined
            || !value[i].trigger) {
            return false;
          }
        }
        return true;
      },
    },
  },
  components: {
    TransitionCollapse,
    IconCross,
    IconCheck,
  },
  data: () => ({
    validationMessageIsVisible: false,
    validationStatus: '',
    validationMessage: '',
    validationTimer: null,
  }),
  watch: {
    value: {
      handler() {
        for (let i = 0; i < this.rules.length; i += 1) {
          if (this.rules[i].trigger === 'input') {
            clearTimeout(this.validationTimer);

            this.validationTimer = setTimeout(() => {
              this.validateInput().then(r => r).catch(e => e);
            }, inputDelay);
          }
        }
      },
    },
  },
  computed: {
    invalidIconIsVisible() {
      return this.validationStatus === 'failed' && !this.noIcons;
    },
    validIconIsVisible() {
      return this.validationStatus === 'success' && !this.noIcons && !this.noSuccessIcon;
    },
  },
  methods: {
    async validateInput() {
      this.validationMessage = '';

      for (let i = 0; i < this.rules.length; i += 1) {
        if (!this.rules[i].validationFunction(this.value)) {
          this.validationMessageIsVisible = true;
          this.validationMessage += this.$t(this.rules[i].message);
          this.validationStatus = 'failed';

          return Promise.reject();
        }
      }

      this.validationMessageIsVisible = false;
      this.validationStatus = 'success';

      return Promise.resolve();
    },
    resetValidationStatus() {
      this.validationMessageIsVisible = false;
      this.validationMessage = '';
      this.validationStatus = '';
    },
  },
};
</script>


<style lang="scss" scoped>
.text-input {
  -webkit-appearance: none;
  border-radius: 0;
  transition: border 0.3s;

  &::placeholder {
    @apply text-cool-grey;
  }

  &:focus,
  &:hover {
    @apply border-charcoal-grey;
  }

  &.password:not(.is-empty) {
    font-size: 20px;
    letter-spacing: 5px;
  }
}

.icon {
  top: 16px;
  right: 16px;
}
</style>
