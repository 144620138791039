<template>
  <Transition name="fade-in-linear">
    <div
        v-if="isVisible"
        role="dialog"
        aria-modal="true"
        class="modal-wrapper fixed flex flex-col items-center z-50"
    >
      <div class="absolute pin bg-dark-grey opacity-90 z-45"></div>
      <div
          class="content-wrapper w-full max-w-450 m-auto z-50"
          @click.stop
          @touchstart.stop
      >
        <div class="flex flex-row justify-between px-24 py-20 bg-white uppercase">
          <slot name="header" />
          <IconCross
              tabindex="0"
              class="flex-no-shrink ml-20 text-charcoal-grey cursor-pointer"
              @click.native.stop.prevent="doClose"
              @keydown.space.native.stop.prevent="doClose"
          />
        </div>
        <div class="content overflow-y-auto m-auto px-37 py-30 bg-pure-white">
          <slot />
        </div>
      </div>
    </div>
  </Transition>
</template>


<script>
import IconCross from '@/components/icon/IconCross';
import { addClass, removeClass } from '@/utils/dom';


export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    isCloseArea: {
      type: Boolean,
      default: false,
    },
    lockScroll: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    IconCross,
  },
  data: () => ({
    modalIsVisibleClassname: 'modal--is-visible',
  }),
  mounted() {
    if (this.isVisible) this.addListeners();
  },
  watch: {
    isVisible: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.addListeners();
          addClass(document.body, this.modalIsVisibleClassname);

          if (this.lockScroll) addClass(document.body, 'lock-scroll');
        } else {
          this.removeListeners();
          removeClass(document.body, this.modalIsVisibleClassname);

          if (this.lockScroll) removeClass(document.body, 'lock-scroll');
        }
      },
    },
  },
  methods: {
    onDocumentPress() {
      // Closing modal when clicking outside of it
      if (!document.body.classList.contains(this.modalIsVisibleClassname) || this.isCloseArea) return;
      this.doClose();
      removeClass(document.body, this.modalIsVisibleClassname);
    },
    doClose() {
      this.$emit('update:isVisible', false);
    },
    addListeners() {
      document.addEventListener('click', this.onDocumentPress);
      document.addEventListener('touchstart', this.onDocumentPress);
      document.addEventListener('keyup', (event) => {
        if (event.keyCode === 27 && this.isVisible) {
          this.doClose();
        }
      });
    },
    removeListeners() {
      document.removeEventListener('click', this.onDocumentPress);
      document.removeEventListener('touchstart', this.onDocumentPress);
    },
  },
  destroyed() {
    this.removeListeners();
  },
};
</script>


<style lang="scss" scoped>
$modal-spacing: 40px;
$modal-header-height: 60px;

.modal-wrapper {
  @apply pin-l pin-r pin-b;

  top: 0;

  .content-wrapper {
    max-height: calc(100vh - #{$modal-spacing * 2});
    -webkit-tap-highlight-color: transparent;
  }

  .content {
    max-height: calc(100vh - #{$modal-header-height} - #{$modal-spacing * 2});
  }
}
</style>
