<template>
  <div>
    <div
        class="flex items-center justify-between bg-pure-white h-full"
        :class="{ 'shadow': uiStickyNavTopShadowIsVisible }"
    >
      <h1 class="flex-no-shrink px-40 truncate text-style-3 uppercase">
        {{ viewTitle }}
      </h1>
      <div class="flex items-center h-full text-style-7 cursor-pointer">
        <div
            class="h-full flex items-center pr-20"
            @click="onSignInPress"
            @click.stop="onNavigationItemPress(0)"
        >
          <IconConstructionWorker
              class="flex-no-shrink pr-5"
              :class="{
                  'text-faded-red': visibleNavigationItemIndex === 0,
                  'text-charcoal-grey': visibleNavigationItemIndex !== 0,
              }"
          />
          <div
              class="select-none"
              :class="{ 'text-faded-red': visibleNavigationItemIndex === 0 }"
          >
            <p>
              {{ accountOptionLabel }}
            </p>
          </div>
        </div>

        <div
            class="flex-no-shrink h-full flex items-center pl-20 pr-32 uppercase select-none"
            :class="{ 'text-faded-red': visibleNavigationItemIndex === 1 }"
            @click.stop="onNavigationItemPress(1)"
        >
          <span>
            {{ $i18n.locale }}
          </span>
        </div>
      </div>
    </div>

    <Transition name="zoom-in-top">
      <div
          v-show="dropdownIsVisible"
          class="absolute pin-t pin-r w-243 mt-50 pt-25 pl-40 pb-25
          bg-pure-white border-t border-white shadow"
      >
        <RouterLink
            v-if="navigationItem.routeTo"
            v-for="(navigationItem, index) in visibleNavigationItems.items"
            :key="`navigation-item-${index}`"
            :to="navigationItem.routeTo"
            class="navigation-item relative text-style-7 hover:text-faded-red"
            :class="{
                'mb-25': index !== visibleNavigationItems.items.length -1,
                'is-language-selector': visibleNavigationItemIndex === 1,
            }"
            tag="div"
        >
          <span>
            {{ $t(navigationItem.navText) }}
          </span>
          <IconCheckbox
              v-if="visibleNavigationItems.selectedItemCheckboxIsVisible"
              class="icon h-20 text-faded-red absolute"
          />
        </RouterLink>
        <div
            v-else
            class="navigation-item text-style-7 hover:text-faded-red"
            @click="navigationItem.onPress"
        >
          {{ $t(navigationItem.navText) }}
        </div>
      </div>
    </Transition>

    <AuthModal />
  </div>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import AuthModal from '@/components/auth/AuthModal';
import IconCheckbox from '@/components/icon/IconCheckbox';
import IconConstructionWorker from '@/components/icon/IconConstructionWorker';
import { availableLocales } from '@/i18n';
import { addClass, removeClass } from '@/utils/dom';


export default {
  components: {
    AuthModal,
    IconConstructionWorker,
    IconCheckbox,
  },
  data() {
    return {
      baseUrl: process.env.BASE_URL,
      visibleNavigationItemIndex: -1,
      dropdownIsVisibleClassname: 'top-bar-dropdown--is-visible',
    };
  },
  created() {
    document.addEventListener('click', this.onDocumentPress);
  },
  computed: {
    ...mapState('ui', {
      uiStickyNavTopShadowIsVisible: 'stickyNavTopShadowIsVisible',
    }),
    ...mapState('user', {
      userIsInitialLoading: 'isInitialLoading',
      userFirstName: 'firstName',
    }),
    ...mapState('productDetails', {
      productName: 'productName',
    }),
    ...mapGetters('user', {
      userIsAuthenticated: 'isAuthenticated',
    }),
    navigationItems() {
      return [{
        items: [{
          navText: 'navigation.top.bookmarks_text',
          routeTo: { name: 'BookmarksView', params: { locale: this.$i18n.locale } },
        }, {
          navText: 'navigation.top.sign_out_text',
          onPress: this.onSignOutPress,
        }],
      }, {
        selectedItemCheckboxIsVisible: true,
        items: [{
          navText: 'navigation.top.language_selector.de',
          routeTo: { params: { locale: availableLocales[0], noScrollReset: true } },
        }, {
          navText: 'navigation.top.language_selector.en',
          routeTo: { params: { locale: availableLocales[1], noScrollReset: true } },
        }],
      }];
    },
    visibleNavigationItems() {
      if (this.visibleNavigationItemIndex === -1) {
        return {
          items: [],
          selectedItemCheckboxIsVisible: false,
        };
      }

      const navigationItems = this.navigationItems[this.visibleNavigationItemIndex];
      return {
        items: navigationItems.items || [],
        selectedItemCheckboxIsVisible: navigationItems.selectedItemCheckboxIsVisible || false,
      };
    },
    dropdownIsVisible() {
      if (!this.userIsAuthenticated && this.visibleNavigationItemIndex === 0) return false;
      return !!this.visibleNavigationItems.items.length;
    },
    viewTitle() {
      if (this.$route.name === 'ProductDetailsView' && !this.productName) return '';
      if (this.productName) return this.productName;
      if (this.$route.meta.viewTitles) {
        return this.$t(this.$route.meta.viewTitles[this.$route.params.searchType]);
      }
      if (!this.$route.meta.viewTitle) return '-';

      return this.$t(this.$route.meta.viewTitle);
    },
    accountOptionLabel() {
      if (this.userIsInitialLoading) return '';
      if (this.userIsAuthenticated) return `${this.userFirstName}`;
      return this.$t('navigation.top.sign_in_text');
    },
  },
  methods: {
    ...mapActions('user', {
      logUserOut: 'logUserOut',
    }),
    ...mapActions('ui', {
      updateModalState: 'updateModalState',
    }),
    onNavigationItemPress(itemIndex) {
      // Handle item toggling
      if (itemIndex === 0 && !this.userIsAuthenticated) this.visibleNavigationItemIndex = -1;
      else if (this.visibleNavigationItemIndex !== -1
        && this.visibleNavigationItemIndex === itemIndex) this.visibleNavigationItemIndex = -1;
      else this.visibleNavigationItemIndex = itemIndex;

      // Add class to body to be able to close the dropdown by clicking outside
      if (this.visibleNavigationItemIndex !== -1) {
        addClass(document.body, this.dropdownIsVisibleClassname);
      } else {
        removeClass(document.body, this.dropdownIsVisibleClassname);
      }
    },
    onDocumentPress() {
      // Closing dropdown when clicking outside of it
      if (document.body.classList.contains(this.dropdownIsVisibleClassname)
        && this.visibleNavigationItemIndex !== -1) {
        this.visibleNavigationItemIndex = -1;
        removeClass(document.body, this.dropdownIsVisibleClassname);
      }
    },
    onSignInPress() {
      if (this.userIsAuthenticated) return;

      this.updateModalState({ isVisible: true, type: 'log-in' });
    },
    onSignOutPress() {
      this.logUserOut();
    },
  },
  destroyed() {
    document.removeEventListener('click', this.onDocumentPress);
  },
};
</script>


<style lang="scss" scoped>
.navigation-item {
  @apply cursor-pointer;

  .icon {
    @apply invisible;

    left: -28px;
  }

  &.router-link-exact-active {
    @apply text-faded-red;

    .icon {
      @apply visible;
    }
  }

  &.is-language-selector.router-link-active {
    @apply text-faded-red;

    .icon {
      @apply visible;
    }
  }
}
</style>
