<template>
  <Transition
      @before-enter="onBeforeEnter"
      @enter="onEnter"
      @after-enter="onAfterEnter"
      @before-leave="onBeforeLeave"
      @leave="onLeave"
      @after-leave="onAfterLeave"
  >
    <slot />
  </Transition>
</template>


<script>
// Based on ElementUI's collapse-transition
/* eslint-disable no-param-reassign */
import { addClass, removeClass } from '@/utils/dom';


export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onBeforeEnter(el) {
      if (this.isDisabled) return;

      addClass(el, 'collapse-transition');
      if (!el.dataset) el.dataset = {};

      el.dataset.oldPaddingTop = el.style.paddingTop;
      el.dataset.oldPaddingBottom = el.style.paddingBottom;

      el.style.height = '0';
      el.style.paddingTop = 0;
      el.style.paddingBottom = 0;
    },
    onEnter(el) {
      if (this.isDisabled) return;

      el.dataset.oldOverflow = el.style.overflow;
      if (el.scrollHeight !== 0) {
        el.style.height = `${el.scrollHeight}px`;
        el.style.paddingTop = el.dataset.oldPaddingTop;
        el.style.paddingBottom = el.dataset.oldPaddingBottom;
      } else {
        el.style.height = '';
        el.style.paddingTop = el.dataset.oldPaddingTop;
        el.style.paddingBottom = el.dataset.oldPaddingBottom;
      }

      el.style.overflow = 'hidden';
    },
    onAfterEnter(el) {
      if (this.isDisabled) return;

      // for safari: remove class then reset height is necessary
      removeClass(el, 'collapse-transition');

      el.style.height = '';
      el.style.overflow = el.dataset.oldOverflow;
    },
    onBeforeLeave(el) {
      if (this.isDisabled) return;

      if (!el.dataset) el.dataset = {};
      el.dataset.oldPaddingTop = el.style.paddingTop;
      el.dataset.oldPaddingBottom = el.style.paddingBottom;
      el.dataset.oldOverflow = el.style.overflow;

      el.style.height = `${el.scrollHeight}px`;
      el.style.overflow = 'hidden';
    },
    onLeave(el) {
      if (this.isDisabled) return;

      if (el.scrollHeight !== 0) {
        // for safari: add class after set height, or it will jump to zero height suddenly, weired
        addClass(el, 'collapse-transition');

        el.style.height = 0;
        el.style.paddingTop = 0;
        el.style.paddingBottom = 0;
      }
    },
    onAfterLeave(el) {
      if (this.isDisabled) return;

      removeClass(el, 'collapse-transition');

      el.style.height = '';
      el.style.overflow = el.dataset.oldOverflow;
      el.style.paddingTop = el.dataset.oldPaddingTop;
      el.style.paddingBottom = el.dataset.oldPaddingBottom;
    },
  },
};
</script>


<style lang="scss" scoped>
@import '../../styles/variables';

.collapse-transition {
  transition: $collapse-transition-duration height ease-in-out,
  $collapse-transition-duration padding-top ease-in-out,
  $collapse-transition-duration padding-bottom ease-in-out;
}
</style>
