<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="size"
      :height="size"
      viewBox="0 0 20 20"
  >
    <path
        class="stroke-current"
        stroke-width="3"
        fill="none"
        fill-rule="nonzero"
        d="M5 9.98L9.73 15 16 4"
    />
  </svg>
</template>


<script>
export default {
  props: {
    size: {
      type: Number,
      default: 20,
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
