<template>
  <RouterView
      v-if="routeIsRendered"
      class="w-full"
  />
</template>


<script>
import { mapState, mapGetters } from 'vuex';


export default {
  data: () => ({
    routeIsRendered: false,
  }),
  watch: {
    currentRouteRequiresAuth: {
      immediate: true,
      handler(newValue) {
        if (!newValue) return;
        if (!this.userIsAuthenticated && !this.userIsInitialLoading) {
          this.$router.push({ name: 'HomeView' });
        }
      },
    },
    userIsInitialLoading: {
      immediate: true,
      handler(newValue) {
        if (!this.currentRouteRequiresAuth) {
          this.routeIsRendered = true;
          return;
        }

        // If current route requiresAuth
        if (newValue) return; // User auth check not done yet
        if (this.userIsAuthenticated) {
          this.routeIsRendered = true;
        } else {
          this.$router.push({ name: 'HomeView' });
        }
      },
    },
    userIsAuthenticated: {
      immediate: true,
      handler(newValue) {
        if (!this.currentRouteRequiresAuth) return;
        if (!newValue && !this.userIsInitialLoading) {
          this.$router.push({ name: 'HomeView' });
        }
      },
    },
  },
  computed: {
    ...mapState('user', {
      userIsInitialLoading: 'isInitialLoading',
    }),
    ...mapGetters('user', {
      userIsAuthenticated: 'isAuthenticated',
    }),
    currentRouteRequiresAuth() {
      return this.$route.matched.some(record => record.meta.requiresAuth);
    },
  },
};
</script>


<style lang="scss" scoped>

</style>
