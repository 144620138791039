<template>
  <div>
    <label
        role="checkbox"
        :aria-checked="isChecked"
        :aria-disabled="isDisabled"
        :tabindex="isDisabled || (isDisabled && isChecked) ? -1 : 0"
        class="ui-checkbox flex items-center focus:outline-none"
        :class="{
            'is-disabled': isDisabled,
            'no-label-color': noLabelColor,
        }"
        @keydown.space.stop.prevent="onChange"
    >
      <input
          v-bind="$attrs"
          type="checkbox"
          :value="val"
          :checked="isChecked"
          :disabled="isDisabled"
          tabindex="-1"
          class="ui-input absolute w-23 h-23 opacity-0"
          @change="onChange"
      >
      <span
          class="checkbox inline-block align-middle w-23 h-23 border"
          :class="[validationMessageIsVisible ? 'border-faded-red' : ' border-pinkish-grey' ]"
      >
        <Transition name="zoom-in-center">
          <IconCheckbox
              v-show="isChecked"
              width="19"
              height="19"
              class="text-faded-red"
          />
        </Transition>
      </span>
      <span
          class="label inline-block flex-shrink align-middle text-style-7 ml-15 select-none"
          :class="{
              'text-faded-red': (isChecked || (isChecked && isDisabled)) && !noLabelColor,
              'text-pinkish-grey': isDisabled && !isChecked,
          }"
      >
        <slot />
      </span>
    </label>
    <TransitionCollapse>
      <p
          v-if="validationMessageIsVisible"
          class="mt-4 ml-39 text-sm text-faded-red"
          :class="{ 'mb-13': validationStatus === 'failed' }"
      >
        {{ validationMessage }}
      </p>
    </TransitionCollapse>
</div>

</template>


<script>
import IconCheckbox from '@/components/icon/IconCheckbox';
import TransitionCollapse from '@/components/transition/TransitionCollapse';
import { isString } from 'lodash';


export default {
  inheritAttrs: false,
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    val: {
      type: String,
      default: '',
    },
    noSuccessIcon: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
      validator(value) {
        for (let i = 0; i < value.length; i += 1) {
          if (!value[i].message
            || value[i].validationFunction === undefined
            || !value[i].trigger) {
            return false;
          }
        }
        return true;
      },
    },
    noLabelColor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    validationMessageIsVisible: false,
    validationStatus: '',
    validationMessage: '',
    validationTimer: null,
  }),
  components: {
    IconCheckbox,
    TransitionCollapse
  },
  computed: {
    isChecked() {
      return this.value.includes(this.val);
    },
  },
  methods: {
    async validateInput() {
      this.validationMessage = '';

      for (let i = 0; i < this.rules.length; i += 1) {
        if (!this.rules[i].validationFunction(this.value.length)) {
          this.validationMessageIsVisible = true;
          this.validationMessage += this.$t(this.rules[i].message);
          this.validationStatus = 'failed';

          return Promise.reject();
        }
      }

      this.validationMessageIsVisible = false;
      this.validationStatus = 'success';

      return Promise.resolve();
    },
    onChange() {
      const value = this.value.slice();

      if (this.isChecked && value.includes(this.val)) {
        value.splice(value.indexOf(this.val), 1);
      } else {
        value.push(this.val);
      }
      this.$emit('update-bookmark-list', value);
      this.$emit('input', value);
    },
    isString(value) {
      return isString(value);
    },
  },
  watch: {
    value: {
      handler() {
        for (let i = 0; i < this.rules.length; i += 1) {
          if (this.rules[i].trigger === 'input') {
            clearTimeout(this.validationTimer);

            this.validationTimer = setTimeout(() => {
              this.validateInput().then(r => r).catch(e => e);
            }, inputDelay);
          }
        }
      },
    },
  },
};
</script>


<style lang="scss" scoped>
.ui-checkbox {
  .checkbox {
    flex: 0 0 23px;
    transition: border 0.3s;
  }
}

// Handling environments that have a precise pointing device
@media (any-pointer: fine) {
  .ui-checkbox {
    &.is-disabled {
      @apply cursor-not-allowed;

      .ui-input {
        @apply cursor-not-allowed;
      }
    }

    &:not(.is-disabled) {
      @apply cursor-pointer;

      .ui-input {
        @apply cursor-pointer;
      }
    }

    &:not(.no-label-color):not(.is-disabled) {
      &:focus .checkbox,
      &:hover .checkbox {
        @apply border-charcoal-grey;
      }
    }
  }
}
</style>
