import Vue from 'vue';
import VueRouter from 'vue-router';
import { loadLocaleAsync } from '@/i18n';
import store from '../store';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) { // if has a hash...
      return { selector: to.hash } // scroll to the element
    }
    if (to.params.noScrollReset) return false;
    return savedPosition || { x: 0, y: 0 };
  },
});


// Handling Google Analytics tracking
router.beforeEach((to, from, next) => {
  const { gtag } = window;

  // gtag.js tracking
  if (typeof gtag !== 'undefined') {
    gtag('config', 'G-YVG3HY0V33', {
      page_path: to.path,
    });
  }

  next();
});

// Configuration for i18n
router.beforeEach((to, from, next) => {
  // Load new locale if the locale changed
  loadLocaleAsync(to.params.locale).then(() => next());
});

// Resetting selectedAssemblyTypeSpecs when leaving the search/results routes
router.beforeEach((to, from, next) => {
  const searchTypeIsPreset = store.getters['assemblySearch/searchTypeIsPreset'];
  const searchTypeIsManual = store.getters['assemblySearch/searchTypeIsManual'];
  const getIsConfirmRedirect = store.getters['ui/getIsConfirmRedirect'];
  const filterIsActive = store.getters['assemblySearch/filterIsActive'];
  if (from.params.locale !== to.params.locale) {
    next();
  } else if (!getIsConfirmRedirect
    && filterIsActive
    && ((from.path.includes('manual') && to.name !== 'ProductSearchResultsView' && to.name !== 'ProductDetailsView')
    || (from.path.includes('preset') && to.name !== 'ProductSearchResultsView' && to.name !== 'ProductDetailsView')
    || (from.name === 'ProductSearchResultsView' && to.name !== 'AssemblySearchView' && to.name !== 'ProductDetailsView')
    || (from.name === 'ProductSearchResultsView' && to.path.includes('manual') && searchTypeIsPreset)
    || (from.name === 'ProductDetailsView' && to.path.includes('manual') && searchTypeIsPreset)
    || (from.name === 'ProductDetailsView' && to.name !== 'AssemblySearchView' && to.name !== 'ProductSearchResultsView' && (searchTypeIsPreset || searchTypeIsManual))
    || (from.name === 'ProductDetailsView' && to.name !== 'AssemblySearchView' && searchTypeIsPreset && searchTypeIsManual))
    ) {
    store.dispatch('ui/updateModalState', {
      isVisible: true,
      type: 'confirm-redirection-type',
      redirectRouteName: to.name,
    });
    return;
  }


  if (store.state.ui.modal.isConfirmRedirect) {
    store.dispatch('assemblySearch/resetSearchState');
    store.dispatch('assemblySearch/resetSelectedSpecificationValues');
    store.commit('ui/SET_MODAL_REDIRECT_CONFIRM', false);
  }
  next();
});


export default router;
