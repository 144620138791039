<template>
  <div>
    <p class="text-sm text-slate-grey mt-20 mb-32">
      {{ $t('constructions_search.steps.third_step.filter_form.description_text') }}
    </p>

    <div
        v-if="true"
        class="flex flex-row mb-25"
    >
      <!-- RW -->
      <div class="w-1/2 mr-10 tablet-landscape:mr-50">
        <p class="flex flex-row items-center">
          <span class="font-sans-bold text-base text-charcoal-grey pr-10">
            {{ $t('constructions_search.steps.third_step.filter_form.rw.main_text') + ' - R' }}
            <sub>
              w
            </sub>
            (dB)
          </span>
          <!-- eslint-disable-next-line -->
          <UiTooltip :tooltip-content="$t('constructions_search.steps.third_step.filter_form.tooltips.airborn_insulation_text')" />
        </p>
        <div>
          <VueSlider
              ref="slider"
              v-model="selectedValues.rwAdaptation.range"
              :min="adaptationFilterLimits.rw.min"
              :max="adaptationFilterLimits.rw.max"
              :tooltip="'always'"
              :tooltip-style="sliderTooltipStyle"
              :process-style="{ backgroundColor: '#d13239' }"
              class="mx-13 mt-27 mb-30"
          />
        </div>
        <p class="flex flex-row items-center mt-25 mb-5">
          <span class="text-sm text-slate-grey pr-10">
            {{ $t('constructions_search.steps.third_step.filter_form.rw.adaptation_text') }}
          </span>
          <!-- eslint-disable-next-line -->
          <UiTooltip :tooltip-content="$t('constructions_search.steps.third_step.filter_form.tooltips.airborn_insulation_adaptation_text')" />
        </p>
        <UiRadioGroup v-model="selectedValues.rwAdaptation.offset">
          <UiRadioButton
              v-for="(rwAdaptation, index) in rwAdaptationOptions"
              :key="`rw-adaptation-${index}`"
              :val="rwAdaptation.value"
              :label="rwAdaptation.label"
              :sub-label="rwAdaptation.subLabel"
              class="block w-1/3"
          />
        </UiRadioGroup>
      </div>
      <!-- LNW -->
      <div
          v-if="lnwFilterIsVisible"
          class="w-1/2 ml-10 tablet-landscape:ml-50"
      >
        <p class="flex flex-row items-center">
          <span class="font-sans-bold text-base text-charcoal-grey pr-10">
            {{ $t('constructions_search.steps.third_step.filter_form.lnw.main_text') + ' - L' }}
            <sub>
              n,w
            </sub>
            (dB)
          </span>
          <!-- eslint-disable-next-line -->
          <UiTooltip :tooltip-content="$t('constructions_search.steps.third_step.filter_form.tooltips.footfall_insulation_text')" />
        </p>
        <div>
          <VueSlider
              ref="slider"
              v-model="selectedValues.lnwAdaptation.range"
              :min="adaptationFilterLimits.lnw.min"
              :max="adaptationFilterLimits.lnw.max"
              :tooltip="'always'"
              :tooltip-style="sliderTooltipStyle"
              :process-style="{ backgroundColor: '#d13239' }"
              class="mx-13 mt-27 mb-30 "
          />
        </div>
        <p class="flex flex-row items-center mt-25 mb-5">
          <span class="text-sm text-slate-grey pr-10">
            {{ $t('constructions_search.steps.third_step.filter_form.lnw.adaptation_text') }}
          </span>
          <!-- eslint-disable-next-line -->
          <UiTooltip :tooltip-content="$t('constructions_search.steps.third_step.filter_form.tooltips.footfall_insulation_adaptation_text')" />
        </p>
        <UiRadioGroup
            v-if="lnwAdaptationOptions"
            v-model="selectedValues.lnwAdaptation.offset"
        >
          <UiRadioButton
              v-for="(lnwAdaptation, index) in lnwAdaptationOptions"
              :key="`lnw-adaptation-${index}`"
              :val="lnwAdaptation.value"
              :label="lnwAdaptation.label"
              :sub-label="lnwAdaptation.subLabel"
              class="block w-1/3"
          />
        </UiRadioGroup>
      </div>
    </div>

    <div>
      <p class="flex flex-row items-center mb-18">
        <span class="font-sans-bold text-base text-charcoal-grey pr-10">
          {{ $t('constructions_search.steps.third_step.filter_form.fire_resistance_text') }}
        </span>
        <!-- eslint-disable-next-line -->
        <UiTooltip :tooltip-content="$t('constructions_search.steps.third_step.filter_form.tooltips.fire_resistance_text')" />
      </p>
      <div
          v-for="(fireResistanceGroup, groupIndex) in fireResistanceGroups"
          :key="`fire-resistance-group-${groupIndex}`"
          class="flex flex-row flex-wrap"
      >
        <UiCheckbox
            v-for="(fireResistance, optionIndex) in fireResistanceGroup"
            :key="`fire-resistance-checkbox-${optionIndex}`"
            v-model="selectedValues.fireResistances"
            :is-disabled="disabledFireResistances.includes(fireResistance.value)"
            :val="fireResistance.value"
            class="w-1/4 tablet-landscape:w-1/6 mb-25"
        >
          {{ fireResistance.value }}
        </UiCheckbox>
      </div>
    </div>

    <div v-if="customFilterLabels.length">
      <p class="flex flex-row items-center mb-18">
        <span class="font-sans-bold text-base text-charcoal-grey pr-10">
          {{ $t('constructions_search.steps.third_step.filter_form.additional_specs_text') }}
        </span>
        <!-- eslint-disable-next-line -->
        <UiTooltip :tooltip-content="$t('constructions_search.steps.third_step.filter_form.tooltips.additional_specs_text')" />
      </p>
      <div class="flex flex-row flex-wrap">
        <UiCheckbox
            v-for="(customFilter, index) in customFilterLabels"
            :key="`custom-filter-${index}`"
            v-model="selectedValues.customFilters"
            :val="customFilter.value"
            :is-disabled="disabledCustomFilters.includes(customFilter.value)"
            class="w-1/2 tablet-landscape:w-1/3 mb-25"
        >
          <template v-if="$t(customFilter.translationKey).sub_text">
            <i18n
                :path="`${customFilter.translationKey}.main_text`"
                tag="p"
            >
              <sub place="subText">
                {{ $t(`${customFilter.translationKey}.sub_text`) }}
              </sub>
            </i18n>
          </template>
          <template v-else>
            {{ $t(customFilter.translationKey) }}
          </template>
        </UiCheckbox>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {
  debounce, cloneDeep, groupBy, sortBy,
} from 'lodash';
import VueSlider from 'vue-slider-component';
import UiTooltip from '../ui/UiTooltip';
import UiCheckbox from '../ui/UiCheckbox';
import UiRadioGroup from '../ui/UiRadioGroup';
import UiRadioButton from '../ui/UiRadioButton';
import adaptationLabels from '@/helpers/adaptationLabelsHelper';


const sliderTooltipStyle = {
  backgroundColor: '#3b3c40',
  borderColor: '#3b3c40',
  color: '#ffffff',
  padding: '11px 14px',
  top: '24px'
};


export default {
  components: {
    VueSlider,
    UiTooltip,
    UiCheckbox,
    UiRadioGroup,
    UiRadioButton,
  },
  data() {
    return {
      adaptationFilterLimits: {
        rw: {
          min: 0,
          max: 0,
        },
        lnw: {
          min: 0,
          max: 0,
        },
      },
      lnwFilterIsVisible: false,
      selectedValues: cloneDeep(this.$store.state.assemblySearch.selectedSpecificationValues),
      disabledFireResistances: [],
      disabledCustomFilters: [],
      sliderTooltipStyle,
    };
  },
  created() {
    this.updateAllProducts();
  },
  watch: {
    selectedValues: {
      deep: true,
      // eslint-disable-next-line func-names
      handler: debounce(async function (newValue) {
        await this.updateSelectedSpecificationValues(cloneDeep(newValue));

        if (this.allProductsIsLoading) return;
        this.applySearchFilters();
      }, 250),
    },
    allProductsIsLoading: {
      handler(newValue) {
        if (newValue) return;

        this.applySearchFilters();
      },
    },
    selectedAssemblyType: {
      immediate: true,
      deep: true,
      handler(newValue) {
        // Calculating the main filter limits
        const mainFilterLimits = cloneDeep(this.selectedAssemblyType.data.mainFilters);

        if (this.selectedAssemblyTypeSpecific
          && this.selectedAssemblyTypeSpecific.data.minAirborneSoundInsulation) {
          // eslint-disable-next-line max-len
          mainFilterLimits.rw.min = this.selectedAssemblyTypeSpecific.data.minAirborneSoundInsulation;
        }
        if (this.selectedAssemblyTypeSpecific
          && this.selectedAssemblyTypeSpecific.data.maxFootfallSoundInsulation
          && newValue.data.mainFilters.lnw) {
          // eslint-disable-next-line max-len
          mainFilterLimits.lnw.max = this.selectedAssemblyTypeSpecific.data.maxFootfallSoundInsulation;
        }

        this.adaptationFilterLimits.rw.min = mainFilterLimits.rw.min;
        this.adaptationFilterLimits.rw.max = mainFilterLimits.rw.max;

        // Setting the rwAdaptation range values depending on if they where previously selected
        if (this.selectedValues.rwAdaptation.range[0] === 0
          && this.selectedValues.rwAdaptation.range[1] === 0) {
          this.selectedValues.rwAdaptation.range = [mainFilterLimits.rw.min,
            mainFilterLimits.rw.max];
        } else {
          this.selectedValues.rwAdaptation.range = [this.selectedValues.rwAdaptation.range[0],
            this.selectedValues.rwAdaptation.range[1]];
        }

        // Setting lnw if available
        if (mainFilterLimits.lnw) {
          this.lnwFilterIsVisible = true;
          this.adaptationFilterLimits.lnw.min = mainFilterLimits.lnw.min;
          this.adaptationFilterLimits.lnw.max = mainFilterLimits.lnw.max;

          if (this.selectedValues.lnwAdaptation.range[0] === 0
            && this.selectedValues.lnwAdaptation.range[1] === 0) {
            this.selectedValues.lnwAdaptation.range = [mainFilterLimits.lnw.min,
              mainFilterLimits.lnw.max];
          } else {
            this.selectedValues.lnwAdaptation.range = [this.selectedValues.lnwAdaptation.range[0],
              this.selectedValues.lnwAdaptation.range[1]];
          }
        } else {
          this.lnwFilterIsVisible = false;
          this.adaptationFilterLimits.lnw.min = 0;
          this.adaptationFilterLimits.lnw.max = 0;
          this.selectedValues.lnwAdaptation.range = [0, 0];
        }
      },
    },
    selectedFireResistance: {
      immediate: true,
      handler(newValue) {
        // Reset disabled fire resistances
        this.disabledFireResistances = [];

        if (!newValue) return;

        // Get current FireResistance
        const forceSelectedFireResistance = this.DBValues.fireResistances
          .find(fireResistance => fireResistance.value === newValue.value);
        // Sort the FR by valueOrder
        const fireResistancesByValueOrder = sortBy(this.DBValues.fireResistances, 'valueOrder');

        // Set disabled fire resistances if a Fire resistance value is set
        for (let i = 0; i < fireResistancesByValueOrder.length; i += 1) {
          this.disabledFireResistances.push(fireResistancesByValueOrder[i].value);

          if (fireResistancesByValueOrder[i].valueOrder
            === forceSelectedFireResistance.valueOrder) {
            break;
          }
        }

        // Set selected fireResistance
        this.selectedValues.fireResistances = [forceSelectedFireResistance.value];

        // Set selected custom filters
        if (!newValue.customFilters) return;
        this.selectedValues.customFilters = [...newValue.customFilters];
        this.disabledCustomFilters = newValue.customFilters;
      },
    },
  },
  computed: {
    ...mapState('assemblySearch', {
      DBValues: 'DBValues',
      activeSelectedValues: 'selectedValues',
      activeStepIndex: 'activeStepIndex',
      allProductsIsLoading: 'allProductsIsLoading',
    }),
    ...mapGetters('assemblySearch', {
      searchTypeIsPreset: 'searchTypeIsPreset',
      selectedAssemblyType: 'selectedAssemblyType',
      selectedAssemblyTypeSpecific: 'selectedAssemblyTypeSpecific',
      selectedFireResistance: 'selectedFireResistance',
    }),
    fireResistanceGroups() {
      const fireResistances = this.DBValues.fireResistances.filter(elem => elem.value !== 'E 30' && elem.value !== 'E 60' && elem.value !== 'E 90');
      
      return groupBy(fireResistances, 'groupIndex');
    },
    customFilterLabels() {
      if (!this.selectedAssemblyType) return [];

      return this.selectedAssemblyType.data.supportedCustomFilters
        .map(customFilter => ({
          value: customFilter.value,
          translationKey: `constructions_search.steps.third_step.filter_form.${customFilter.translationKey}`,
        }));
    },
    rwAdaptationOptions() {
      if (!this.selectedAssemblyType) return false;

      return [{
        label: this.$t('constructions_search.steps.third_step.filter_form.without_rw_lnw_text'),
        value: '',
      }, ...this.selectedAssemblyType.data.mainFilters.rw.adaptation.map(rwAdaptation => ({
        label: adaptationLabels.rw[rwAdaptation].label || rwAdaptation,
        subLabel: adaptationLabels.rw[rwAdaptation].subLabel || '',
        value: rwAdaptation,
      }))];
    },
    lnwAdaptationOptions() {
      if (!this.selectedAssemblyType || !this.selectedAssemblyType.data.mainFilters.lnw) {
        return false;
      }

      return [{
        label: this.$t('constructions_search.steps.third_step.filter_form.without_rw_lnw_text'),
        value: '',
      }, ...this.selectedAssemblyType.data.mainFilters.lnw.adaptation
        .map(lnwAdaptation => ({
          label: adaptationLabels.lnw[lnwAdaptation].label || lnwAdaptation,
          subLabel: adaptationLabels.lnw[lnwAdaptation].subLabel || '',
          value: lnwAdaptation,
        }))];
    },
  },
  methods: {
    ...mapActions('assemblySearch', {
      updateSelectedSpecificationValues: 'updateSelectedSpecificationValues',
      updateAllProducts: 'updateAllProducts',
      applySearchFilters: 'applySearchFilters',
    }),
  },
};
</script>


<style lang="scss">
.vue-slider {
  .vue-slider-rail {
    height: 4px;
    background-color: #ccc;
    border-radius: 15px;
  }
  /* Setting tolltip style */
  .vue-slider-dot-tooltip {
    top: 28px !important;
    cursor: pointer;

    &.vue-merged-tooltip {
      top: 22px !important;
    }

    .vue-slider-dot-tooltip-inner {
      @apply font-sans-bold;

      min-width: 18px;
      border-radius: 0;
      text-align: center;
    }
  }

  /* Hidding the dots */
  .vue-slider-dot .vue-slider-dot-handle {
    opacity: 0;
  }
}

.vue-slider-tooltip:before {
  display: none;
}
</style>
