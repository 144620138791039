<template>
  <div class="relative">
    <div class="absolute pin-t w-full min-h-85 bg-pure-white"></div>
    <div class="relative w-full h-full flex flex-col">
      <SearchSteps />

      <div
          class="px-40 pb-40 flex-1"
          :class="{ 'bg-pure-white': filterStepIsVisible }"
      >
        <Transition
            name="fade-in-linear"
            mode="out-in"
            appear
        >
          <div v-if="searchTypeIsPreset && activeStepIndex === 0">
            <UiExpandableSelector
                ref="selector-region"
                key="selector-region"
                v-model="selectedValues.region"
                placeholder-label="constructions_search.selectors.region_text"
                :items="regionOptions"
                :item-class-names="['grid-template-columns-3',
                'tablet-landscape:grid-template-columns-4']"
                :is-collapsed.sync="regionsIsCollapsed"
                class="mb-15"
            />

            <UiExpandableSelector
                ref="selector-building-type"
                key="selector-building-type"
                v-model="selectedValues.buildingType"
                placeholder-label="constructions_search.selectors.building_type_text"
                :items="buildingTypeOptions"
                :item-class-names="['grid-template-columns-3',
                'tablet-landscape:grid-template-columns-4']"
                :is-disabled="!selectedValues.region"
                :is-collapsed.sync="buildingTypesIsCollapsed"
                class="mb-15"
            />

            <UiExpandableSelector
                ref="selector-building-class"
                key="selector-building-class"
                v-model="selectedValues.buildingClass"
                placeholder-label="constructions_search.selectors.building_class_text"
                :items="buildingClassOptions"
                :item-class-names="['grid-template-columns-3',
                'tablet-landscape:grid-template-columns-4']"
                :is-disabled="!selectedValues.region || !selectedValues.buildingType"
                :is-collapsed.sync="buildingClassIsCollapsed"
            />
          </div>

          <div v-else-if="(searchTypeIsPreset && activeStepIndex === 1)
            || (searchTypeIsManual && activeStepIndex === 0)">
            <UiExpandableSelector
                :key="`assembly-type-${$route.params.searchType}`"
                v-model="selectedValues.assemblyType"
                :disabled-values="disabledAssemblyTypes"
                type="image"
                placeholder-label="constructions_search.selectors.assembly_type_text"
                :items="assemblyTypeOptions"
                :item-class-names="['grid-template-columns-3',
                'tablet-landscape:grid-template-columns-5']"
                :is-collapsable="!searchTypeIsManual"
                :is-collapsed.sync="assemblyTypeIsCollapsed"
                @change="onAssemblyTypeChange"
                @item-disabled-pressed="onDisabledAssemblyTypePress"
            />

            <Transition name="fade-in-linear">
              <UiExpandableSelector
                  ref="selector-assembly-type-specific"
                  key="selector-assembly-type-specific"
                  v-show="assemblyTypeSpecificIsVisible && searchTypeIsPreset"
                  v-model="selectedValues.assemblyTypeSpecific"
                  :disabled-values="disabledAssemblyTypeSpecifics"
                  placeholder-label="constructions_search.selectors.assembly_type_specific_text"
                  :items="assemblyTypeSpecificOptions"
                  item-class-names="grid-template-columns-2"
                  :is-collapsed.sync="assemblyTypeSpecificIsCollapsed"
                  class="mt-15"
                  :class="{ 'mb-15': assemblyTypeSpecificSpecsIsVisible }"
                  @item-disabled-pressed="onDisabledAssemblyTypeSpecificPress"
              />
            </Transition>

            <Transition name="fade-in-linear">
              <div
                  ref="assembly-type-specific-specs"
                  v-if="assemblyTypeSpecificSpecsIsVisible && searchTypeIsPreset"
                  class="inline-block mt-15"
              >
                <h3 class="font-sans-bold text-base text-charcoal-grey mb-15">
                  {{ $t('constructions_search.steps.second_step.min_requirements_text') }}
                </h3>
                <div class="flex flex-row">
                  <div
                      v-for="(item, index) in minimumRequirementItems"
                      v-if="item.isVisible"
                      :key="index"
                      class="flex flex-col"
                  >
                    <div class="bg-faded-red w-210 mr-2">
                      <div class="flex flex-row items-center justify-between
                    bg-rouge pl-31 pr-20 py-15">
                        <h4 class="font-sans-bold text-base text-pure-white uppercase truncate">
                          {{ $t(item.title) }}
                        </h4>
                        <img
                            :src="baseUrl + item.iconSrc"
                            alt="Sound wave"
                            class="block w-30 h-30"
                        />
                      </div>
                      <div class="p-31 pt-20">
                        <p class="text-base text-pure-white mb-10">
                          {{ item.value.label }}
                          <sub v-if="item.value.subLabel">{{ item.value.subLabel }}</sub>
                        </p>
                        <p class="font-sans-bold text-xl text-pure-white">
                          {{ item.value.formatted }}
                        </p>
                      </div>
                    </div>
                    <div
                        v-if="item.description"
                        class="text-sm text-cool-grey mt-17"
                    >
                      {{ item.description }}
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>

          <div v-else-if="filterStepIsVisible">
            <SearchSpecificationsForm />
          </div>
        </Transition>
      </div>
    </div>

    <SearchModal />
  </div>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { cloneDeep, sortBy } from 'lodash';
import assemblyTypeImages from '@/helpers/assemblyTypeHelper';
import UiExpandableSelector from '@/components/ui/UiExpandableSelector';
import SearchSteps from '@/components/search/SearchSteps';
import SearchSpecificationsForm from '@/components/search/SearchSpecificationsForm';
import SearchModal from '@/components/search/SearchModal';
import variables from '@/styles/variables.scss';


const minimumDelayDuration = 750;
const collapseTransitionDuration = parseInt(variables.collapseTransitionDuration.slice(0, -2), 10);


export default {
  components: {
    UiExpandableSelector,
    SearchSteps,
    SearchSpecificationsForm,
    SearchModal,
  },
  data() {
    return {
      baseUrl: process.env.BASE_URL,
      selectedValues: cloneDeep(this.$store.state.assemblySearch.selectedValues),
      regionsIsCollapsed: true,
      buildingTypesIsCollapsed: true,
      buildingClassIsCollapsed: true,
      assemblyTypeIsCollapsed: this.$route.params.searchType !== 'manual',
      assemblyTypeSpecificIsCollapsed: true,
      assemblyTypeSpecificIsVisible: false,
      assemblyTypeSpecificSpecsIsVisible: false,
      firstLoadTimerIsDone: false,
      DBAssemblyTypeSpecifics: this.$store.state.assemblySearch.DBValues.assemblyTypeSpecifics,
    };
  },
  created() {
    if (this.$route.params.noReset) {
      if (this.searchTypeIsPreset) {
        this.assemblyTypeSpecificIsCollapsed = true;
        this.assemblyTypeSpecificIsVisible = true;
        this.assemblyTypeSpecificSpecsIsVisible = true;
      }
      return;
    }

    // Handling case when the user comes from the ProductSearchResultsView (bottom nav)
    if (this.searchTypeIsManual && this.activeStepIndex >= 2) {
      this.updateActiveStepIndex(0);
    }

    // Handling if the user comes from a preset search type to a manual (bottom nav)
    if (this.$store.state.assemblySearch.searchType === 'preset'
      && this.searchTypeIsManual) {
      this.resetSearchState().then(() => {
        this.selectedValues = cloneDeep(this.$store.state.assemblySearch.selectedValues);
      });
      this.resetSelectedSpecificationValues();
    }

    this.updateSearchType(this.$route.params.searchType);

    setTimeout(() => {
      this.firstLoadTimerIsDone = true;

      if (this.isInitialLoading) return;

      this.handleExpandableSelectors();
    }, minimumDelayDuration);
  },
  watch: {
    '$route.params.searchType': {
      async handler(newValue) {
        await this.resetSearchState();
        this.selectedValues = cloneDeep(this.$store.state.assemblySearch.selectedValues);

        this.updateSearchType(newValue);

        if (this.isInitialLoading || !this.firstLoadTimerIsDone) return;
        this.handleExpandableSelectors();
      },
    },
    isInitialLoading: {
      handler(newValue) {
        if (!this.firstLoadTimerIsDone || newValue) return;

        this.handleExpandableSelectors();
      },
    },
    selectedValues: {
      deep: true,
      handler(newValue) {
        this.updateSelectedValues(cloneDeep(newValue));
      },
    },
    'selectedValues.region': {
      handler(newValue, oldValue) {
        this.resetSelectedSpecificationValues();
        if (!oldValue) {
          this.buildingTypesIsCollapsed = false;
          setTimeout(() => {
            this.handleWindowScrollForItem(this.$refs['selector-building-type'].$el);
          }, collapseTransitionDuration);
        }
        this.handleAssemblyTypeSpecific(true);
        this.handleDisabledFireResistances();
      },
    },
    'selectedValues.buildingType': {
      handler(newValue, oldValue) {
        this.resetSelectedSpecificationValues();
        if (!oldValue) {
          this.buildingClassIsCollapsed = false;
          setTimeout(() => {
            this.handleWindowScrollForItem(this.$refs['selector-building-class'].$el);
          }, collapseTransitionDuration);
        }
        this.handleAssemblyTypeSpecific(true);
        this.handleDisabledFireResistances();
      },
    },
    'selectedValues.buildingClass': {
      handler() {
        this.resetSelectedSpecificationValues();
        setTimeout(() => {
          this.handleWindowScrollForItem(window);
        }, collapseTransitionDuration);
      },
    },
    activeStepIndex: {
      handler() {
        this.handleWindowScrollForItem(window);
        this.handleExpandableSelectors();
      },
    },
    'selectedValues.assemblyType': {
      async handler() {
        this.resetSelectedSpecificationValues();
        if (this.searchTypeIsManual) return;

        this.assemblyTypeSpecificSpecsIsVisible = false;
        this.selectedValues.assemblyTypeSpecific = '';

        // handleAssemblyTypeSpecific can block the main thread,
        // therefore delaying the operation to happen after the collapse transition from
        // the assemblyType
        this.assemblyTypeSpecificIsVisible = true;
        setTimeout(async () => {
          await this.handleAssemblyTypeSpecific(true);

          this.assemblyTypeSpecificIsCollapsed = false;
          await this.$nextTick();

          setTimeout(() => {
            this.handleWindowScrollForItem(this.$refs['selector-assembly-type-specific'].$el);
          }, collapseTransitionDuration);
        }, collapseTransitionDuration);
      },
    },
    'selectedValues.assemblyTypeSpecific': {
      async handler(newValue, oldValue) {
        this.resetSelectedSpecificationValues();
        if (oldValue) return;

        this.assemblyTypeSpecificSpecsIsVisible = true;
        await this.$nextTick(); // Wait on assemblyTypeSpecificSpecsIsVisible update

        setTimeout(() => {
          const buttonHeight = 60;

          this.handleWindowScrollForItem(this.$refs['assembly-type-specific-specs'], buttonHeight);
        }, collapseTransitionDuration);
      },
    },
  },
  computed: {
    ...mapState('assemblySearch', {
      isInitialLoading: 'isInitialLoading',
      DBValues: 'DBValues',
      activeStepIndex: 'activeStepIndex',
      activeSelectedValues: 'selectedValues',
    }),
    ...mapGetters('assemblySearch', {
      selectedAssemblyTypeSpecific: 'selectedAssemblyTypeSpecific',
      selectedFireResistance: 'selectedFireResistance',
    }),
    searchTypeIsManual() {
      return this.$route.params.searchType === 'manual';
    },
    searchTypeIsPreset() {
      return this.$route.params.searchType === 'preset';
    },
    regionOptions() {
      return this.formatDBValues(this.DBValues.regions);
    },
    buildingTypeOptions() {
      return this.formatDBValues(this.DBValues.buildingTypes);
    },
    buildingClassOptions() {
      return this.formatDBValues(this.DBValues.buildingClasses);
    },
    assemblyTypeOptions() {
      return sortBy(this.DBValues.assemblyTypes, [o => o.data.order]).map(option => ({
        value: option.value,
        label: option.data[this.$i18n.locale].name,
        imageSrc: `${this.baseUrl}images/icons/assembly-types/house/${assemblyTypeImages[option.value]}.svg`,
      }));
    },
    assemblyTypeSpecificOptions() {
      return this.formatDBValues(this.DBAssemblyTypeSpecifics);
    },
    filterStepIsVisible() {
      return (this.activeStepIndex === 1 && this.searchTypeIsManual)
        || (this.activeStepIndex === 2 && this.searchTypeIsPreset);
    },
    minimumRequirementItems() {
      let dinRegulationOnIsolation = '';
      let regionalRegulationOnFireResistance = '';
      let fireResistanceClassesByBuildingTypeLabel = '';

      if (this.selectedAssemblyTypeSpecific) {
        // eslint-disable-next-line max-len
        const selectedAssemblyTypeSpecificData = this.selectedAssemblyTypeSpecific.data[[this.$i18n.locale]];

        if (selectedAssemblyTypeSpecificData
          && selectedAssemblyTypeSpecificData.dinRegulationOnIsolation) {
          ({ dinRegulationOnIsolation } = selectedAssemblyTypeSpecificData);
        }
        if (selectedAssemblyTypeSpecificData
          && selectedAssemblyTypeSpecificData.regionalRegulationOnFireResistance) {
          ({ regionalRegulationOnFireResistance } = selectedAssemblyTypeSpecificData);
        }
        if (this.selectedFireResistance) {
          fireResistanceClassesByBuildingTypeLabel = this.selectedFireResistance.label;
        }
      }

      return [{
        title: 'constructions_search.steps.second_step.airborne_sound_insulation_text',
        iconSrc: 'images/icons/sound-wave.svg',
        value: {
          label: 'R\'',
          subLabel: 'w',
          formatted: `≥ ${this.selectedAssemblyTypeSpecific.data.minAirborneSoundInsulation} dB`,
        },
        isVisible: !!this.selectedAssemblyTypeSpecific.data.minAirborneSoundInsulation,
        description: dinRegulationOnIsolation,
      }, {
        title: 'constructions_search.steps.second_step.footfall_sound_text',
        iconSrc: 'images/icons/sound-wave.svg',
        value: {
          label: 'L\'',
          subLabel: 'n,w',
          formatted: `≤ ${this.selectedAssemblyTypeSpecific.data.maxFootfallSoundInsulation} dB`,
        },
        isVisible: !!this.selectedAssemblyTypeSpecific.data.maxFootfallSoundInsulation,
      }, {
        title: 'constructions_search.steps.second_step.fire_resistance_text',
        iconSrc: 'images/icons/fire-flame.svg',
        value: {
          label: this.$t('constructions_search.steps.second_step.fire_resistance_class_text'),
          formatted: fireResistanceClassesByBuildingTypeLabel,
        },
        isVisible: true,
        description: regionalRegulationOnFireResistance,
      }];
    },
    disabledAssemblyTypes() {
      if (!this.selectedValues.buildingClass) return [];

      return this.DBValues.disabledAssemblyTypes[this.selectedValues.buildingClass];
    },
    disabledAssemblyTypeSpecifics() {
      if (!this.assemblyTypeSpecificOptions.length
        || !this.DBValues.assemblyTypeSpecifics.length
        || !this.selectedValues.buildingClass) return [];

      return this.DBValues.assemblyTypeSpecifics
        .filter(assemblyTypeSpecific => assemblyTypeSpecific.data
          .fireResistanceClassesByBuildingType[this.selectedValues.buildingClass].value === 'x')
        .map(assemblyTypeSpecific => assemblyTypeSpecific.value);
    },
  },
  methods: {
    ...mapActions('ui', {
      updateModalState: 'updateModalState',
    }),
    ...mapActions('assemblySearch', {
      resetSearchState: 'resetSearchState',
      resetSelectedSpecificationValues: 'resetSelectedSpecificationValues',
      updateActiveStepIndex: 'updateActiveStepIndex',
      nextActiveStepIndex: 'nextActiveStepIndex',
      updateSearchType: 'updateSearchType',
      updateAssemblySpecifics: 'updateAssemblySpecifics',
      updateDisabledAssemblyTypes: 'updateDisabledAssemblyTypes',
      updateSelectedValues: 'updateSelectedValues',
      updateAllProducts: 'updateAllProducts',
    }),
    handleExpandableSelectors() {
      if (this.searchTypeIsPreset) {
        if (this.activeStepIndex === 0
          && (this.selectedValues.region === '' || this.selectedValues.buildingType === '')) {
          const initialLoadDelay = 50;

          if (this.selectedValues.region === '') {
            setTimeout(() => {
              this.regionsIsCollapsed = false;
            }, initialLoadDelay);
          } else {
            setTimeout(() => {
              this.buildingTypesIsCollapsed = false;
            }, initialLoadDelay);
          }
        } else if (this.activeStepIndex === 1 && this.selectedValues.assemblyType === '') {
          setTimeout(() => {
            this.assemblyTypeIsCollapsed = false;
          }, 250);
        } else if (this.activeStepIndex === 1
          && this.assemblyTypeSpecificIsVisible && !this.assemblyTypeSpecificSpecsIsVisible) {
          setTimeout(() => {
            this.assemblyTypeSpecificIsCollapsed = false;
            // setTimeout(() => {
            //   this.handleWindowScrollForItem(this.$refs['selector-assembly-type-specific'].$el);
            // }, collapseTransitionDuration);
          }, 500);
        }
      } else if (this.searchTypeIsManual) {
        this.assemblyTypeIsCollapsed = false;
      }
    },
    onAssemblyTypeChange() {
      if (this.searchTypeIsPreset) {
        this.selectedValues.assemblyTypeSpecific = '';
        this.assemblyTypeSpecificIsCollapsed = true;
      } else if (this.searchTypeIsManual) {
        this.nextActiveStepIndex();
      }
    },
    formatDBValues(DBValues) {
      return sortBy(DBValues, [o => o.data.order]).map(option => ({
        value: option.value,
        label: option.data[this.$i18n.locale].name,
      }));
    },
    async handleAssemblyTypeSpecific(resetAssemblyTypeSpecificIfSet) {
      if (!this.selectedValues.assemblyType
        || !this.selectedValues.region
        || !this.selectedValues.buildingType) return Promise.resolve();

      // Reset assembly type specific value
      if (resetAssemblyTypeSpecificIfSet) {
        this.assemblyTypeSpecificIsCollapsed = true;
        this.assemblyTypeSpecificSpecsIsVisible = false;
        this.selectedValues.assemblyTypeSpecific = '';
      }

      return this.updateAssemblySpecifics().then((DBAssemblyTypeSpecificValues) => {
        this.DBAssemblyTypeSpecifics = DBAssemblyTypeSpecificValues;
        // this.assemblyTypeSpecificOptions = this.formatDBValues(DBAssemblyTypeSpecificValues);

        return Promise.resolve();
      }).catch(() => Promise.reject());
    },
    handleDisabledFireResistances() {
      if (!this.selectedValues.region
        || !this.selectedValues.buildingType) return;

      this.updateDisabledAssemblyTypes();
    },
    handleWindowScrollForItem(element, additionalOffset = 0) {
      const minViewportOffset = 25;
      let windowScrollTopOffset = 0;

      if (element !== window) {
        const viewportHeight = Math.max(document.documentElement.clientHeight,
          window.innerHeight || 0);
        const stickyNavTopHeight = parseInt(variables.stickyNavTopH.slice(0, -2), 10);
        const stickyNavBottomHeight = parseInt(variables.stickyNavBottomH.slice(0, -2), 10);
        const elementTopOffset = element.offsetTop + stickyNavTopHeight;
        const elementBottomOffset = element.offsetTop + element.clientHeight + minViewportOffset
          + stickyNavTopHeight;

        // By default scroll until the bottom of the element is visible
        windowScrollTopOffset = elementBottomOffset - viewportHeight + stickyNavBottomHeight;

        // If the bottom of the element is not fully visible in the viewport scroll until the top of
        // the element is visible
        if (elementTopOffset - stickyNavTopHeight < windowScrollTopOffset) {
          windowScrollTopOffset = element.offsetTop - minViewportOffset;
        }

        // Adding additional offset
        windowScrollTopOffset += additionalOffset;
      }

      if (windowScrollTopOffset < 0) return;
      window.scrollTo({
        top: windowScrollTopOffset,
        left: 0,
        behavior: 'smooth',
      });
    },
    onDisabledAssemblyTypePress() {
      this.updateModalState({ isVisible: true, type: 'disabled-assembly-type' });
    },
    onDisabledAssemblyTypeSpecificPress() {
      this.updateModalState({ isVisible: true, type: 'disabled-assembly-type-specific' });
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
