<template>
  <div class="ui-drop-down flex flex-wrap items-center focus:outline-none relative">
    <select 
      class="cursor-pointer appearance-none h-50 text-input w-full px-16 bg-pure-white border border-pinkish-grey
      outline-none text is-empty"
      :class="isChooseOption ? 'text-style-7' : 'text-style-12'"
      @change="selectItem($event)"
      @input="$emit('input', $event.target.value)"
    >
      <option 
        class="cursor-pointer" 
        value="" 
        selected 
        disabled
      >
        {{ placeholder }}
      </option>
      <option 
        class="cursor-pointer text-style-7" 
        v-for="(option, index) in options" 
        :value="option.name"
        :key="index"
      >
          {{ $t(`countries.${option.name}`) }}
      </option>
    </select>
    <Transition name="fade-in-linear">
      <IconCross
          v-if="invalidIconIsVisible"
          class="icon absolute text-faded-red"
      />
      <IconCheck
          v-else-if="validIconIsVisible"
          class="icon absolute text-emerald"
      />
    </Transition>
    <TransitionCollapse>
      <p
          v-if="validationMessageIsVisible"
          class="mt-4 text-sm text-faded-red w-full"
          :class="{ 'mb-13': validationStatus === 'failed' }"
      >
        {{ validationMessage }}
      </p>
    </TransitionCollapse>
  </div>
</template>

<script>
import TransitionCollapse from '@/components/transition/TransitionCollapse';
import IconCross from '@/components/icon/IconCross';
import IconCheck from '@/components/icon/IconCheck';

export default {
  components: {
    TransitionCollapse,
    IconCross,
    IconCheck,
  },
  props: {
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
      validator(value) {
        for (let i = 0; i < value.length; i += 1) {
          if (!value[i].message
            || value[i].validationFunction === undefined
            || !value[i].trigger) {
            return false;
          }
        }
        return true;
      },
    },
    noIcons: {
      type: Boolean,
      default: false,
    },
    noSuccessIcon: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedItem: '',
    isChooseOption: false,
    validationMessageIsVisible: false,
    validationStatus: '',
    validationMessage: '',
    validationTimer: null,
  }),
  computed: {
    invalidIconIsVisible() {
      return this.validationStatus === 'failed' && !this.noIcons;
    },
    validIconIsVisible() {
      return this.validationStatus === 'success' && !this.noIcons && !this.noSuccessIcon;
    },
  },
  methods: {
    selectItem(event) {
      this.selectedItem = event.target.options[event.target.options.selectedIndex].text;
      this.isChooseOption = true;
    },
    async validateInput() {
      this.validationMessage = '';

      for (let i = 0; i < this.rules.length; i += 1) {
        if (!this.rules[i].validationFunction(this.selectedItem)) {
          this.validationMessageIsVisible = true;
          this.validationMessage += this.$t(this.rules[i].message);
          this.validationStatus = 'failed';

          return Promise.reject();
        }
      }

      this.validationMessageIsVisible = false;
      this.validationStatus = 'success';

      return Promise.resolve();
    },
    resetValidationStatus() {
      this.validationMessageIsVisible = false;
      this.validationMessage = '';
      this.validationStatus = '';
    },
  },
  watch: {
    selectedItem: {
      handler() {
        for (let i = 0; i < this.rules.length; i += 1) {
          if (this.rules[i].trigger === 'input') {
            clearTimeout(this.validationTimer);

            this.validationTimer = setTimeout(() => {
              this.validateInput().then(r => r).catch(e => e);
            }, inputDelay);
          }
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-drop-down:after {
  content:'<>';
  font-family: "DIN Next LT W01 Regular";
  font-size: 1.0625rem;
  color:#ccc;
  -webkit-transform:rotate(90deg);
  -moz-transform:rotate(90deg);
  -ms-transform:rotate(90deg);
  transform:rotate(90deg);
  top: 14px;
  right: 14px; 
  padding:0 0 2px;
  position:absolute;
  pointer-events:none;
}
</style>