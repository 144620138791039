<template>
  <div class="base-layout min-h-full flex flex-col">
    <StickyNavTop class="sticky-nav-top fixed pin-t w-full z-40" />
    <ViewCookies />
    <div class="view-container flex flex-col bg-white">
      <div
          id="view-route-container"
          class="flex-1 flex"
      >
        <Transition
            name="fade-in-linear-fast"
            mode="out-in"
            appear
        >
          <ViewRoute />
        </Transition>
      </div>

      <ViewFooter class="flex-none" />
    </div>

    <StickyNavBottom class="fixed pin-b w-full z-20" />
  </div>
</template>


<script>
import StickyNavTop from '@/components/sticky-nav/StickyNavTop';
import StickyNavBottom from '@/components/sticky-nav/StickyNavBottom';
import ViewRoute from '@/components/view/ViewRoute';
import ViewFooter from '@/components/view/ViewFooter';
import ViewCookies from '@/components/view/ViewCookies';
import variables from '@/styles/variables.scss';


export default {
  components: {
    StickyNavTop,
    StickyNavBottom,
    ViewRoute,
    ViewFooter,
    ViewCookies,
  },
  data: () => ({
    viewportWidth: 0,
    viewportHeight: 0,
    viewRouteContainerRef: null,
  }),
  watch: {
    $route: {
      handler() {
        this.setViewRouteContainerMinHeight();
      },
    },
  },
  created() {
    this.viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    this.viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    window.addEventListener('resize', () => {
      const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

      if (this.viewportWidth === viewportWidth) return;

      this.viewportWidth = viewportWidth;
      this.viewportHeight = Math.max(document.documentElement.clientHeight,
        window.innerHeight || 0);
      this.setViewRouteContainerMinHeight();
    });
  },
  mounted() {
    this.viewRouteContainerRef = document.getElementById('view-route-container');
    this.setViewRouteContainerMinHeight();
  },
  methods: {
    setViewRouteContainerMinHeight() {
      if (this.$route.meta.hasNoFooter) return;

      // Setting the view-route-container to be the min height of the viewport without
      // the sticky navs, only on initial load so that the browsers (iOS)
      // don't recalculate the 100vh
      this.viewRouteContainerRef
        .style.minHeight = `calc(${this.viewportHeight}px - (${variables.stickyNavTopH} + ${variables.stickyNavBottomH}))`;
    },
  },
};
</script>


<style lang="scss" scoped>
@import '../styles/variables';

.base-layout {
  .sticky-nav-top {
    height: $sticky-nav-top-h;
  }

  .view-container {
    margin-top: $sticky-nav-top-h;
    margin-bottom: $sticky-nav-bottom-h;
    flex: 1 0 auto;
  }
}
</style>
