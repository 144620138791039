<template>
<div>
  <UiModal 
    :is-visible.sync="modalIsVisible"
    :is-close-area="isCloseArea"
  >
    <template slot="header">
      <h3 class="font-sans-bold text-base text-faded-red">
        {{ modalOptions.title }}
      </h3>
    </template>
    <p class="text-style-7 mb-35">
      {{ modalOptions.description }}
    </p>
    <UiButton
      class="w-full font-sans-bold mt-14 py-15 text-pure-white  uppercase"
      :class="modalTypeIsConfirmRedirection ? 'bg-faded-red-two' : 'bg-charcoal-grey'"
      @click.native.prevent="onButtonPress('continue')"
      @keydown.space.native.prevent="onButtonPress('continue')"
    >
      {{ modalOptions.firstButtonLabel }}
    </UiButton>
    <UiButton
      v-if="modalTypeIsConfirmRedirection"
      class="w-full font-sans-bold mt-14 py-15 text-pure-white bg-charcoal-grey uppercase"
      @click.native.prevent="onButtonPress"
      @keydown.space.native.prevent="onButtonPress"
    >
      {{ modalOptions.secondButtonLabel }}
    </UiButton>
  </UiModal>
</div>

</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import UiModal from '@/components/ui/UiModal';
import UiButton from '@/components/ui/UiButton';


export default {
  components: {
    UiModal,
    UiButton,
  },
  data: () => ({
    modalIsVisible: false,
    isCloseArea: false,
  }),
  beforeRouteLeave (to, from, next) {
    const answer = window.confirm('Вы хотите уйти? У вас есть несохранённые изменения!')
    if (answer) {
      next()
    } else {
      next(false)
    }
  },
  watch: {
    'uiModal.isVisible': {
      immediate: true,
      handler(newValue) {
        if (!this.modalTypeIsDisabledAssemblyType && !this.modalTypeIsDisabledAssemblyTypeSpecific && !this.modalTypeIsConfirmRedirection) return;
        if (this.modalTypeIsConfirmRedirection) this.isCloseArea = true;
        this.modalIsVisible = newValue;
      },
    },
    modalIsVisible: {
      handler(newValue) {
        this.updateModalState({ isVisible: newValue });
      },
    },
  },
  computed: {
    ...mapState('ui', {
      uiModal: 'modal',
    }),
    ...mapState('assemblySearch', {
      searchType: 'searchType',
    }),
    ...mapGetters('ui', {
      modalTypeIsDisabledAssemblyType: 'modalTypeIsDisabledAssemblyType',
      modalTypeIsDisabledAssemblyTypeSpecific: 'modalTypeIsDisabledAssemblyTypeSpecific',
      modalTypeIsConfirmRedirection: 'modalTypeIsConfirmRedirection',
      getRedirectRouteName: 'getRedirectRouteName',
    }),
    activeModalTypeTranslationKey() {
      if (this.modalTypeIsConfirmRedirection) return 'confirm_redirection_type';
      if (this.modalTypeIsDisabledAssemblyType) return 'disabled_assembly_type';
      if (this.modalTypeIsDisabledAssemblyTypeSpecific) return 'disabled_assembly_type_specific';
    },
    modalOptions() {
      if (this.modalTypeIsConfirmRedirection) {
        return {
          title: this.$t(`constructions_search.modal.${this.activeModalTypeTranslationKey}.title_text`),
          description: this.$t(`constructions_search.modal.${this.activeModalTypeTranslationKey}.description_text`),
          firstButtonLabel: this.$t(`constructions_search.modal.${this.activeModalTypeTranslationKey}.first_button_text`),
          secondButtonLabel: this.$t(`constructions_search.modal.${this.activeModalTypeTranslationKey}.second_button_text`),
        }
      }
      return {
        title: this.$t(`constructions_search.modal.${this.activeModalTypeTranslationKey}.title_text`),
        description: this.$t(`constructions_search.modal.${this.activeModalTypeTranslationKey}.description_text`),
        firstButtonLabel: this.$t(`constructions_search.modal.${this.activeModalTypeTranslationKey}.button_text`),
      };
    },
  },
  methods: {
    ...mapActions('ui', {
      updateModalState: 'updateModalState',
    }),
    onButtonPress(command) {
      const searchType = this.searchType === 'preset' ? 'manual' : 'preset';
      if (this.modalTypeIsConfirmRedirection && command === 'continue') {
        this.$router.push({ name: this.getRedirectRouteName, params: { searchType: searchType } }).catch(err => console.log(err));
        this.updateModalState({ isVisible: false, type: '', redirectRouteName: '', isConfirmRedirect: true, });
        this.modalIsVisible = false;
      } else {
        this.modalIsVisible = false;
        this.updateModalState({ isVisible: false, type: '', redirectRouteName: '', isConfirmRedirect: false, });
      }
    },
  },
};
</script>


<style lang="scss" scoped>

</style>
