export default {
  namespaced: true,
  state: {
    productName: '',
  },
  getters: {},
  mutations: {
    SET_PRODUCT_NAME(state, productName) {
      state.productName = productName;
    },
  },
  actions: {
    resetProductName: ({ commit }) => {
      commit('SET_PRODUCT_NAME', '');
    },
    updateProductName: ({ commit }, productName) => {
      commit('SET_PRODUCT_NAME', productName);
    },
  },
};
