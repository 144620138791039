import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';


const FBApp = firebase.initializeApp({
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FB_DATABASE_URL,
  projectId: process.env.VUE_APP_FB_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
});
const FBFirestoreSettings = {
  timestampsInSnapshots: true,
};
const firestoreRef = FBApp.firestore();
firestoreRef.settings(FBFirestoreSettings);


// Auth service
export const FBAuth = FBApp.auth();

// Firestore service
export const FBFirestoreRef = firestoreRef;

// Storage service
export const FBStorageRef = firebase.storage().ref();

// Global FIrebase module
export const FBModule = firebase;

export function getUsers() {
  return FBFirestoreRef.collection('users');
}

export function getUser(userId) {
  return getUsers().doc(userId);
}