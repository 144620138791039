<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="size"
      :height="size"
      viewBox="0 0 30 30"
  >
    <g
        fill="none"
        fill-rule="nonzero"
        transform="translate(4 5)"
    >
      <circle
          :fill="backgroundColor"
          cx="10.5"
          cy="10.5"
          r="10.5"
      />
      <g :fill="formColor">
        <path d="M9 10h3v6H9zM9 5h3v3H9z" />
      </g>
    </g>
  </svg>
</template>


<script>
import { colors } from '@/../tailwind';


export default {
  props: {
    size: {
      type: Number,
      default: 30,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    backgroundColor() {
      return this.isActive ? colors['faded-red'] : colors.white;
    },
    formColor() {
      return this.isActive ? colors.white : colors['cool-grey'];
    },
  },
};
</script>


<style lang="scss" scoped>
circle, g {
  transition: fill .3s; // TODO: Export to common transition
}
</style>
