import { FBModule, FBFirestoreRef } from '@/helpers/firebaseServicesHelper';
import store from '@/store';

let userBookmarkListsUnsubscribe = null;
let userBookmarkedProductsUnsubscribe = null;
const DBUsersRef = FBFirestoreRef.collection('users');


export default {
  install: () => {
    // Subscribe to user Auth change (login/logout)
    FBModule.auth().onAuthStateChanged((user) => {
      // No user is signed in
      if (!user) {
        if (userBookmarkListsUnsubscribe) userBookmarkListsUnsubscribe();
        if (userBookmarkedProductsUnsubscribe) userBookmarkedProductsUnsubscribe();

        store.dispatch('user/resetState');
        if (store.state.user.isInitialLoading) store.dispatch('user/updateIsInitialLoading', false);

        return;
      }

      // User is signed in
      store.dispatch('user/updateUser', user).then(() => {
        if (store.state.user.isInitialLoading) store.dispatch('user/updateIsInitialLoading', false);
      });

      // Subscribe to get the bookmarkLists
      userBookmarkListsUnsubscribe = DBUsersRef.doc(user.uid)
        .collection('bookmarkLists')
        .onSnapshot((querySnapshot) => {
          const bookmarkLists = [];

          querySnapshot.forEach((doc) => {
            bookmarkLists.push({
              id: doc.id,
              name: doc.data().name || '',
            });
          });

          store.dispatch('user/bookmarks/updateBookmarkLists', bookmarkLists);
        }, (error) => {
          console.error(error);
        });

      // Subscribe to get the bookmarkedProducts
      userBookmarkedProductsUnsubscribe = DBUsersRef.doc(user.uid)
        .collection('bookmarkedProducts')
        .onSnapshot((querySnapshot) => {
          const bookmarkedProducts = [];

          querySnapshot.forEach((doc) => {
            bookmarkedProducts.push({
              id: doc.id,
              data: doc.data(),
            });
          });

          store.dispatch('user/bookmarks/updateBookmarkedProducts', bookmarkedProducts);
        }, (error) => {
          console.error(error);
        });
    });
  },
};
