export default {
  namespaced: true,
  state: {
    modal: {
      isVisible: false,
      type: '',
      redirectRouteName: '',
      isConfirmRedirect: false,
    },
    authFormData: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      companyName: '',
      street: '',
      zip: null,
      city: '',
      country: '',
      phoneNumber: null,
    },
    bookmarkFormData: {
      name: '',
    },
    selectedBookmarkListView: {
      id: '',
      name: '',
    },
    selectedBookmarksLists: [],
    stickyNavTopShadowIsVisible: true,
  },
  getters: {
    modalTypeIsLogIn: state => state.modal.type === 'log-in',
    modalTypeIsSignUp: state => state.modal.type === 'sign-up',
    modalTypeIsResetPasswordSendEmail: state => state.modal.type === 'reset-password-send-email',
    modalTypeIsNewBookmarkList: state => state.modal.type === 'new-bookmark-list',
    modalTypeIsNewBookmarkListFromSaveBookmark: state => state.modal.type === 'new-bookmark-list-from-save-bookmarked-product',
    modalTypeIsRenameBookmarkList: state => state.modal.type === 'rename-bookmark-list',
    modalTypeIsDeleteBookmarkList: state => state.modal.type === 'delete-bookmark-list',
    modalTypeIsSaveBookmarkedProduct: state => state.modal.type === 'save-bookmarked-product',
    modalTypeIsDisabledAssemblyType: state => state.modal.type === 'disabled-assembly-type',
    modalTypeIsDisabledAssemblyTypeSpecific: state => state.modal.type === 'disabled-assembly-type-specific',
    modalTypeIsConfirmRedirection: state => state.modal.type === 'confirm-redirection-type',
    getRedirectRouteName: state => state.modal.redirectRouteName,
    getIsConfirmRedirect: state => state.modal.isConfirmRedirect,
  },
  mutations: {
    RESET_STATE(state) {
      state.modal = {
        isVisible: false,
        // type: '',
      };
    },
    SET_MODAL_IS_VISIBLE(state, isVisible) {
      state.modal.isVisible = isVisible;
    },
    SET_MODAL_TYPE(state, type) {
      state.modal.type = type;
    },
    SET_MODAL_REDIRECT_CONFIRM(state, isConfirm) {
      state.modal.isConfirmRedirect = isConfirm;
    },
    SET_MODAL_ROUTE_NAME(state, routeName) {
      state.modal.redirectRouteName = routeName;
    },
    SET_AUTH_FORM_DATA(state, authFormData) {
      state.authFormData = authFormData;
    },
    SET_BOOKMARK_FORM_DATA(state, bookmarkFormData) {
      state.bookmarkFormData = bookmarkFormData;
    },
    SET_SELECTED_BOOKMARK_LIST_VIEW(state, selectedBookmarkListView) {
      state.selectedBookmarkListView = selectedBookmarkListView;
    },
    ADD_SELECTED_BOOKMARKS_LISTS(state, selectedBookmarkList) {
      state.selectedBookmarksLists.push(selectedBookmarkList);
    },
    UPDATE_SELECTED_BOOKMARKS_LISTS(state, selectedBookmarkList) {
      state.selectedBookmarksLists = selectedBookmarkList;
    },
    TOGGLE_STICKY_NAV_TOP_SHADOW_IS_VISIBLE(state, stickyNavTopShadowIsVisible) {
      state.stickyNavTopShadowIsVisible = stickyNavTopShadowIsVisible;
    },
  },
  actions: {
    updateStickyNavTopShadowIsVisible({ commit }, stickyNavTopShadowIsVisible) {
      commit('TOGGLE_STICKY_NAV_TOP_SHADOW_IS_VISIBLE', stickyNavTopShadowIsVisible);
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    updateModalState({ state, commit }, { isVisible, type, redirectRouteName = false, isConfirmRedirect = false }) {
      if (type !== undefined && state.modal.type !== type) commit('SET_MODAL_TYPE', type);
      if (isVisible !== undefined && state.modal.isVisible !== isVisible) commit('SET_MODAL_IS_VISIBLE', isVisible);
      if (redirectRouteName && state.modal.redirectRouteName !== redirectRouteName) commit('SET_MODAL_ROUTE_NAME', redirectRouteName);
      if (isConfirmRedirect !== undefined && state.modal.isConfirmRedirect !== isConfirmRedirect) commit('SET_MODAL_REDIRECT_CONFIRM', isConfirmRedirect);
    },
    updateAuthFormData({ commit }, authFormData) {
      commit('SET_AUTH_FORM_DATA', authFormData);
    },
    async updateBookmarkFormData({ commit }, bookmarkFormData) {
      commit('SET_BOOKMARK_FORM_DATA', bookmarkFormData);
      commit('UPDATE_SELECTED_BOOKMARKS_LISTS', []);
      return Promise.resolve();
    },
    updateSelectedBookmarkListView({ commit }, selectedBookmarkListView) {
      commit('SET_SELECTED_BOOKMARK_LIST_VIEW', selectedBookmarkListView);
    },
    updateSelectedBookmarkList({ state, commit }, selectedBookmarkList) {
      if (state.selectedBookmarksLists.some(elem => elem.id === selectedBookmarkList.id)) {
        const updatedList = state.selectedBookmarksLists.filter(elem => elem.id !== selectedBookmarkList.id);
        commit('UPDATE_SELECTED_BOOKMARKS_LISTS', updatedList);
      } else {
        commit('ADD_SELECTED_BOOKMARKS_LISTS', selectedBookmarkList);
      }
    },
  },
};
