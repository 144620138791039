<template>
  <div
      role="radiogroup"
      class="flex flex-row"
  >
    <slot/>
  </div>
</template>


<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  created() {
    this.$on('input', (value) => {
      this.$emit('change', value);
    });
  },
};
</script>


<style lang="scss" scoped>
.ui-radio-button {
  border-top: 2px solid config('colors.pinkish-grey');
  border-bottom: 2px solid config('colors.pinkish-grey');
}

.ui-radio-button:first-child {
  border-left: 2px solid config('colors.pinkish-grey');
}

.ui-radio-button:last-child {
  border-right: 2px solid config('colors.pinkish-grey');
}
</style>
