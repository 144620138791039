<template>
  <!-- eslint-disable -->
  <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="size"
      :height="size"
      viewBox="0 0 30 30"
  >
    <g
        class="stroke-current"
        stroke-width="1.5"
        fill="none"
        fill-rule="nonzero"
        transform="translate(5 3)"
    >
      <path
          d="M19.375 21c0-4.418-4.197-8-9.375-8S.625 16.582.625 21c0 0 2.61 2 9.448 2 6.837 0 9.302-2 9.302-2z"
      />
      <ellipse
          cx="10"
          cy="9"
          fill="#FFF"
          rx="4.5"
          ry="6"
      />
      <path
          fill="#FFF"
          d="M15.5 8c0-3.866-2.462-7-5.5-7S4.5 4.134 4.5 8h11z"
      />
      <path
          fill="#FFF"
          d="M3.75 7.75h12.5v1H3.75zM8.681 5.25h2.684l.701-4.209A.25.25 0 0 0 11.82.75H8.19a.25.25 0 0 0-.246.293l.737 4.207z"
      />
    </g>
  </svg>
  <!-- eslint-enable -->
</template>


<script>
export default {
  props: {
    size: {
      type: Number,
      default: 30,
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
